<template>
  <div class="animated fadeIn">
    <loading v-if="vLoading" />
    <v-col cols="12">
      <div>
        <div class="pa-3" v-if="checkOut.isCheckOut == false">
          <div v-if="!makeCheckOut">
            <h5>برای {{ userData.name }} فرم تسویه حسابی وجود ندارد.</h5>
            <v-btn
              class="primary-btn mt-4"
              @click="
                makeCheckOut = true;
                paymentUploadedFiles = [];
                myFile.showProgress = false;
                paymentMyFile.paymentShowProgress = false;
              "
              >ایجاد فرم تسویه حساب</v-btn
            >
          </div>
          <div v-if="makeCheckOut">
            <div style="background-color: transparent !important">
              <v-row>
                <v-col>
                  <h3>ایجاد فرم تسویه حساب</h3>
                </v-col>
              </v-row>
            </div>
            <div>
              <hr />
              <!-- new attributes -->
              <v-row class="time-row mt-3">
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <span id="startWorkingDate">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      append-icon="calendar_today"
                      v-model="checkOutData.startWorkingDate"
                      :editable="true"
                      class="date-input"
                    >
                      <template v-slot:label>
                        <div class="d-flex flex-row c-purple">
                          <span class="red--text pe-1">*</span> تاریخ شروع خدمت
                          در شرکت
                        </div>
                      </template>
                    </v-text-field>
                  </span>
                  <date-picker
                    v-model="checkOutData.startWorkingDate"
                    element="startWorkingDate"
                    color="#00a7b7"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <span id="checkOutDate">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      append-icon="calendar_today"
                      v-model="checkOutData.checkOutDate"
                      :editable="true"
                      class="date-input"
                    >
                      <template v-slot:label>
                        <div class="d-flex flex-row c-purple">
                          <span class="red--text pe-1">*</span> تاریخ تسویه حساب
                          سوابق خدمتی
                        </div>
                      </template>
                    </v-text-field>
                  </span>
                  <date-picker
                    v-model="checkOutData.checkOutDate"
                    element="checkOutDate"
                    color="#00a7b7"
                  />
                </v-col>
              </v-row>
              <v-row class="time-row">
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <v-text-field
                    label="جمع سوابق خدمات"
                    outlined
                    dense
                    v-model="checkOutData.serviceRecordsTotal"
                    type="number"
                  >
                    <template v-slot:label>
                      <div class="d-flex flex-row c-purple">
                        <span class="red--text pe-1">*</span> جمع سوابق خدمات
                      </div>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <v-text-field
                    label="مانده مطالبات مرخصی به‌روز"
                    outlined
                    dense
                    v-model="checkOutData.leaveDemandsNum"
                    type="number"
                  >
                    <template v-slot:label>
                      <div class="d-flex flex-row c-purple">
                        <span class="red--text pe-1">*</span>
                        مانده مطالبات مرخصی به‌روز
                      </div>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <hr />
              <v-row>
                <v-col>
                  <h5 class="blue-grey--text text--darken-2">
                    <span class="red--text pe-1">*</span>
                    حقوق و مزایای ثابت دریافتی طی سال
                  </h5>
                  <div class="w-100 checkout-table">
                    <b-table
                      responsive
                      :fields="salaryAndBenefitsFields"
                      :items="checkOutData.salaryAndBenefits"
                      class="mt-5"
                      bordered
                      small
                    >
                      <template v-slot:head()="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.label }}
                        </div>
                      </template>
                      <template v-slot:cell(title)="data">
                        <div
                          class="text-center"
                          style="text-align: center; vertical-align: middle"
                        >
                          {{
                            faSalaryAndBenefits.filter(
                              (x) => x.value == data.value
                            )[0].text
                          }}
                        </div>
                      </template>
                      <template v-slot:cell(value)="data">
                        <div
                          class="d-flex justify-space-between"
                          style="text-align: center; vertical-align: middle"
                        >
                          <vuetify-money
                            v-model="data.item.value"
                            :valueWhenIsEmpty="whenIsEmpty"
                            :options="vMoneyOptions"
                            type="number"
                            class="w-100 me-2"
                            outlined
                            dense
                          ></vuetify-money>
                          ریال
                        </div>
                      </template>
                      <!--<template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>-->
                    </b-table>
                    <div class="mt-5 d-flex px-3">
                      <h5 class="primary--text font-weight-bold">
                        جمع حقوق و مزایا:
                      </h5>
                      <h5 class="ms-1">
                        {{
                          checkOutData.salaryAndBenefits
                            ? Number(computedBenefitsTotal).toLocaleString() +
                              " ریال "
                            : "-"
                        }}
                      </h5>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <hr />
              <v-row>
                <v-col>
                  <h5 class="blue-grey--text text--darken-2">
                    <span class="red--text pe-1">*</span>
                    شرح مطالبات
                  </h5>
                  <div class="w-100 checkout-table">
                    <b-table
                      responsive
                      :fields="demandsFields"
                      :items="checkOutData.demands"
                      class="mt-5"
                      bordered
                      small
                    >
                      <template v-slot:head()="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.label }}
                        </div>
                      </template>
                      <template v-slot:cell(title)="data">
                        <div
                          class="text-center"
                          style="text-align: center; vertical-align: middle"
                        >
                          {{
                            faDemands.filter((x) => x.value == data.value)[0]
                              .text
                          }}
                        </div>
                      </template>
                      <template v-slot:cell(value)="data">
                        <div
                          class="d-flex justify-space-between"
                          style="text-align: center; vertical-align: middle"
                        >
                          <vuetify-money
                            v-model="data.item.value"
                            :valueWhenIsEmpty="whenIsEmpty"
                            :options="vMoneyOptions"
                            type="number"
                            class="w-100 me-2"
                            outlined
                            dense
                          ></vuetify-money>
                          ریال
                        </div>
                      </template>
                      <template v-slot:cell(duration)="data">
                        <div
                          class="d-flex justify-space-between"
                          style="text-align: center; vertical-align: middle"
                        >
                          <v-text-field
                            outlined
                            dense
                            v-model="data.item.duration"
                          ></v-text-field>
                        </div>
                      </template>
                      <template v-slot:cell(description)="data">
                        <div
                          class="d-flex justify-space-between"
                          style="text-align: center; vertical-align: middle"
                        >
                          <v-textarea
                            v-model="data.item.description"
                            outlined
                            rows="1"
                            dense
                          ></v-textarea>
                        </div>
                      </template>
                      <!--<template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>-->
                    </b-table>
                    <div class="mt-5 d-flex px-3">
                      <h5 class="primary--text font-weight-bold">
                        جمع مطالبات:
                      </h5>
                      <h5 class="ms-1">
                        {{
                          checkOutData.demands
                            ? Number(computedDemandsTotal).toLocaleString() +
                              " ریال "
                            : "-"
                        }}
                      </h5>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <hr />
              <v-row>
                <v-col>
                  <h5 class="blue-grey--text text--darken-2">
                    <span class="red--text pe-1">*</span>
                    شرح کسورات
                  </h5>
                  <div class="w-100 checkout-table">
                    <b-table
                      responsive
                      :fields="deductionsFields"
                      :items="checkOutData.deductions"
                      class="mt-5"
                      bordered
                      small
                    >
                      <template v-slot:head()="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.label }}
                        </div>
                      </template>
                      <template v-slot:cell(title)="data">
                        <div
                          class="text-center"
                          style="text-align: center; vertical-align: middle"
                        >
                          {{
                            faDeductions.filter((x) => x.value == data.value)[0]
                              .text
                          }}
                        </div>
                      </template>
                      <template v-slot:cell(value)="data">
                        <div
                          class="d-flex justify-space-between"
                          style="text-align: center; vertical-align: middle"
                        >
                          <vuetify-money
                            v-model="data.item.value"
                            :valueWhenIsEmpty="whenIsEmpty"
                            :options="vMoneyOptions"
                            type="number"
                            class="w-100 me-2"
                            outlined
                            dense
                          ></vuetify-money>
                          ریال
                        </div>
                      </template>
                      <!--<template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>-->
                    </b-table>
                    <div class="mt-5 d-flex px-3">
                      <h5 class="primary--text font-weight-bold">
                        جمع کسورات:
                      </h5>
                      <h5 class="ms-1">
                        {{
                          checkOutData.deductions
                            ? Number(computedDeductionsTotal).toLocaleString() +
                              " ریال "
                            : "-"
                        }}
                      </h5>
                    </div>
                    <hr />
                    <div class="mt-5 d-flex px-3">
                      <h5 class="primary--text font-weight-bold">
                        خالص مبلغ دریافتی محاسبه شده :
                      </h5>
                      <h5 class="ms-1">
                        {{
                          computedDemandsTotal - computedDeductionsTotal >= 0
                            ? Number(
                                computedDemandsTotal - computedDeductionsTotal
                              ).toLocaleString() + " ریال "
                            : "مبلغ محاسبه شده منفی است."
                        }}
                      </h5>
                    </div>
                    <v-row class="mt-5 px-3">
                      <v-col cols="10" class="d-flex">
                        <div class="me-4 mt-2 d-flex flex-column">
                          <h5
                            class="font-weight-bold"
                            :class="
                              computedDemandsTotal - computedDeductionsTotal <
                              checkOutData.paymentAmount
                                ? 'red--text'
                                : 'primary--text'
                            "
                          >
                            <span class="red--text pe-1">*</span>
                            خالص مبلغ دریافتی موردنظر (ریال):
                          </h5>
                          <div class="mt-3">
                            <span
                              class="red--text h4"
                              v-if="
                                computedDemandsTotal - computedDeductionsTotal <
                                checkOutData.paymentAmount
                              "
                              >*مبلغ وارد شده نباید بیشتر از مبلغ محاسبه شده
                              باشد</span
                            >
                          </div>
                        </div>

                        <div class="w-50">
                          <vuetify-money
                            v-model="checkOutData.paymentAmount"
                            :valueWhenIsEmpty="whenIsEmpty"
                            :options="vMoneyOptions"
                            type="number"
                            class="me-2"
                            outlined
                            dense
                          ></vuetify-money>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
              <hr class="mb-5 mt-0" />
              <v-row class="time-row">
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                  <v-text-field
                    label="تنظیم کننده "
                    outlined
                    dense
                    v-model="checkOutData.regulator"
                  >
                    <template v-slot:label>
                      <div class="d-flex flex-row c-purple">
                        <span class="red--text pe-1">*</span>
                        تنظیم کننده
                      </div>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                  <v-text-field
                    label="تایید کننده "
                    outlined
                    dense
                    v-model="checkOutData.confirmer"
                  >
                    <template v-slot:label>
                      <div class="d-flex flex-row c-purple">
                        <span class="red--text pe-1">*</span>
                        تایید کننده
                      </div>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                  <v-text-field
                    label="تصویب کننده "
                    outlined
                    dense
                    v-model="checkOutData.approver"
                  >
                    <template v-slot:label>
                      <div class="d-flex flex-row c-purple">
                        <span class="red--text pe-1">*</span>
                        تصویب کننده
                      </div>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <!-- new attributes -->
              <!--<hr />
              <v-row class="time-row">
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <span id="checkOutdate">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      append-icon="calendar_today"
                      v-model="checkOutData.date"
                      :editable="true"
                      class="date-input"
                    >
                      <template v-slot:label>
                        <div class="d-flex flex-row c-purple">
                          <span class="red--text pe-1">*</span> تاریخ تسویه حساب
                        </div>
                      </template>
                    </v-text-field>
                  </span>

                  <date-picker
                    v-model="checkOutData.date"
                    element="checkOutdate"
                    color="#00a7b7"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <span id="checkOutTime">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      append-icon="schedule"
                      v-model="checkOutData.checkOutTime"
                      label="ساعت تسویه حساب"
                      :editable="true"
                      class="date-input"
                    >
                      <template v-slot:label>
                        <div class="d-flex flex-row c-purple">
                          <span class="red--text pe-1">*</span> ساعت تسویه حساب
                        </div>
                      </template>
                    </v-text-field>
                  </span>

                  <date-picker
                    v-model="checkOutData.checkOutTime"
                    element="checkOutTime"
                    color="#00a7b7"
                    type="time"
                  />
                </v-col>
              </v-row>
              <v-row class="time-row">
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <span id="dateFrom5">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      append-icon="calendar_today"
                      v-model="checkOutData.dateFrom"
                      :editable="true"
                      class="date-input"
                    >
                      <template v-slot:label>
                        <div class="d-flex flex-row c-purple">
                          <span class="red--text pe-1">*</span> تاریخ آغاز کار
                        </div>
                      </template>
                    </v-text-field>
                  </span>

                  <date-picker
                    v-model="checkOutData.dateFrom"
                    element="dateFrom5"
                    color="#00a7b7"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <span id="checkOutStartTime">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      append-icon="schedule"
                      v-model="checkOutData.checkOutStartTime"
                      label="ساعت آغاز کار "
                      :editable="true"
                      class="date-input"
                    >
                      <template v-slot:label>
                        <div class="d-flex flex-row c-purple">
                          <span class="red--text pe-1">*</span> ساعت آغاز کار
                        </div>
                      </template>
                    </v-text-field>
                  </span>

                  <date-picker
                    v-model="checkOutData.checkOutStartTime"
                    element="checkOutStartTime"
                    color="#00a7b7"
                    type="time"
                  />
                </v-col>
              </v-row>
              <v-row class="time-row">
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <span id="dateTo5">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      append-icon="calendar_today"
                      v-model="checkOutData.dateTo"
                      label="تاریخ پایان کار"
                      :editable="true"
                      class="date-input"
                    >
                      <template v-slot:label>
                        <div class="d-flex flex-row c-purple">
                          <span class="red--text pe-1">*</span> تاریخ پایان کار
                        </div>
                      </template>
                    </v-text-field>
                  </span>

                  <date-picker
                    v-model="checkOutData.dateTo"
                    element="dateTo5"
                    color="#00a7b7"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <span id="checkOutEndTime">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      append-icon="schedule"
                      v-model="checkOutData.checkOutEndTime"
                      label="ساعت پایان کار "
                      :editable="true"
                      class="date-input"
                    >
                      <template v-slot:label>
                        <div class="d-flex flex-row c-purple">
                          <span class="red--text pe-1">*</span> ساعت پایان کار
                        </div>
                      </template>
                    </v-text-field>
                  </span>

                  <date-picker
                    v-model="checkOutData.checkOutEndTime"
                    element="checkOutEndTime"
                    color="#00a7b7"
                    type="time"
                  />
                </v-col>
              </v-row>-->
              <hr class="my-5" />
              <h5 class="blue-grey--text text--darken-2">پرداخت تسویه</h5>
              <div class="mt-5 px-2">
                <div class="d-flex">
                  <li class="me-2 h2 primary--text font-weight-bold">
                    مبلغ کل تسویه حساب (﷼):
                  </li>
                  <h6>
                    {{
                      computedDemandsTotal - computedDeductionsTotal >= 0
                        ? Number(
                            computedDemandsTotal - computedDeductionsTotal
                          ).toLocaleString() + " ریال "
                        : "مبلغ محاسبه شده منفی است."
                    }}
                  </h6>
                </div>
                <div class="mt-2 d-flex">
                  <li class="me-2 h2 primary--text font-weight-bold">
                    مبلغ پرداخت (﷼):
                  </li>
                  <h6>
                    {{
                      checkOutData.paymentAmount
                        ? Number(checkOutData.paymentAmount).toLocaleString() +
                          " ریال "
                        : "-"
                    }}
                  </h6>
                </div>
              </div>
              <v-row class="time-row mt-2">
                <v-col>
                  <v-select
                    v-model="checkOutData.transferType"
                    :items="transferTypes"
                    item-text="text"
                    item-value="value"
                    outlined
                    dense
                    label="نحوه پرداخت*"
                  >
                    <template v-slot:label>
                      <div class="d-flex flex-row c-purple">
                        <span class="red--text pe-1">*</span> نحوه پرداخت
                      </div>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <!--<v-row class="time-row">
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <vuetify-money
                    v-model="checkOutData.checkOutAmount"
                    :valueWhenIsEmpty="whenIsEmpty"
                    :options="vMoneyOptions"
                    label=" مبلغ کل تسویه حساب(﷼) *"
                    type="number"
                    outlined
                    dense
                  />
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <vuetify-money
                    v-model="checkOutData.paymentAmount"
                    :valueWhenIsEmpty="whenIsEmpty"
                    :options="vMoneyOptions"
                    label=" مبلغ پرداخت(﷼) *"
                    type="number"
                    outlined
                    dense
                  />
                </v-col>
              </v-row>-->
              <v-row
                v-if="checkOutData.transferType == 'card'"
                class="time-row"
              >
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <v-text-field
                    label="شماره کارت فرستنده"
                    outlined
                    dense
                    v-model="checkOutData.cardNumberSender"
                    type="number"
                    @wheel="$event.target.blur()"
                  >
                    <template v-slot:label>
                      <div class="d-flex flex-row c-purple">
                        <span class="red--text pe-1">*</span> شماره کارت فرستنده
                      </div>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <v-text-field
                    label="شماره کارت گیرنده"
                    outlined
                    dense
                    v-model="checkOutData.cardNumberReciever"
                    type="number"
                    @wheel="$event.target.blur()"
                  >
                    <template v-slot:label>
                      <div class="d-flex flex-row c-purple">
                        <span class="red--text pe-1">*</span> شماره کارت گیرنده
                      </div>
                    </template>
                  </v-text-field></v-col
                >
              </v-row>
              <div
                v-if="checkOutData.transferType == 'cheque'"
                class="time-row mt-4"
              >
                <v-row>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field
                      label="شماره چک"
                      outlined
                      dense
                      v-model="checkOutData.chequeNumber"
                      type="text"
                    >
                      <template v-slot:label>
                        <div class="d-flex flex-row c-purple">
                          <span class="red--text pe-1">*</span> شماره چک
                        </div>
                      </template>
                    </v-text-field></v-col
                  >
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field
                      label="شناسه صیاد"
                      outlined
                      dense
                      v-model="checkOutData.chequeSayyad"
                      type="text"
                    >
                      <template v-slot:label>
                        <div class="d-flex flex-row c-purple">
                          <span class="red--text pe-1">*</span> شناسه صیاد
                        </div>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="time-row">
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field
                      label="نام بانک"
                      outlined
                      dense
                      v-model="checkOutData.chequeBankName"
                      type="text"
                    >
                      <template v-slot:label>
                        <div class="d-flex flex-row c-purple">
                          <span class="red--text pe-1">*</span> نام بانک
                        </div>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <span id="chequeDueDate">
                      <v-text-field
                        outlined
                        dense
                        type="text"
                        append-icon="schedule"
                        v-model="checkOutData.chequeDueDate"
                        label="تاریخ سررسید"
                        :editable="true"
                        class="date-input"
                      >
                        <template v-slot:label>
                          <div class="d-flex flex-row c-purple">
                            <span class="red--text pe-1">*</span> تاریخ سررسید
                          </div>
                        </template>
                      </v-text-field>
                    </span>
                    <date-picker
                      v-model="checkOutData.chequeDueDate"
                      element="chequeDueDate"
                      color="#00a7b7"
                      type="date"
                  /></v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-select
                      outlined
                      dense
                      v-model="checkOutData.chequeStatus"
                      :items="chequeStatuses"
                      item-text="text"
                      item-value="value"
                    >
                      <template v-slot:label>
                        <div class="d-flex flex-row c-purple">
                          <span class="red--text pe-1">*</span>وضعیت چک
                        </div>
                      </template></v-select
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-textarea
                      v-model="checkOutData.chequeDescription"
                      label="توضیحات چک"
                      outlined
                      dense
                    >
                      <template v-slot:label>
                        <div class="d-flex flex-row c-purple">
                          <span class="red--text pe-1">*</span> توضیحات چک
                        </div>
                      </template></v-textarea
                    >
                  </v-col>
                </v-row>
              </div>
              <v-row
                v-if="
                  checkOutData.transferType == 'cheque' ||
                  checkOutData.transferType == 'card'
                "
                class="mt-4 time-row"
              >
                <v-col>
                  <vuetify-money
                    v-model="checkOutData.transactionFee"
                    :valueWhenIsEmpty="whenIsEmpty"
                    :options="vMoneyOptions"
                    label="  *کارمزد انتقال وجه(﷼)"
                    type="number"
                    outlined
                    dense
                  />
                </v-col>
              </v-row>
              <hr class="my-5" />
              <v-row>
                <v-col>
                  <v-file-input
                    @change="paymentUpload(paymentFile)"
                    v-model="paymentFile"
                    outlined
                    dense
                    prepend-inner-icon="upload_file"
                    prepend-icon=""
                    class="file-input payment-upload"
                    label="فایل مربوط به
                        پرداخت"
                  >
                  </v-file-input>
                  <v-progress-linear
                    rounded
                    v-if="paymentMyFile.paymentShowProgress"
                    height="10"
                    :value="paymentMyFile.paymentUploadPercentage"
                    class="mb-4 mt-0"
                  >
                  </v-progress-linear>
                  <v-row>
                    <div
                      v-if="paymentMyFile.paymentIsUploaded"
                      color="green"
                      class="mt-3 mb-1 pa-3"
                      style="font-size: 16px"
                    >
                      فایل با موفقیت بارگذاری شد.
                    </div>
                  </v-row>
                  <div
                    v-if="paymentUploadedFiles.length"
                    class="files-box d-flex flex-column"
                  >
                    <div class="files-title">
                      لیست فایل‌های مربوط به پرداخت ({{
                        paymentUploadedFiles.length
                      }})
                    </div>
                    <span
                      v-for="(item, index) in paymentUploadedFiles"
                      :key="index"
                      class="mb-2 pt-2"
                    >
                      <span @click="deleteFile(index)" class="pointer">
                        <v-icon class="red--text text--lighten-1 pe-2"
                          >mdi-close-circle-outline</v-icon
                        >
                      </span>
                      <a :href="item.url" target="blank">{{
                        showName[index]
                      }}</a>
                    </span>
                  </div>
                </v-col>
              </v-row>
              <v-row class="time-row mt-0">
                <v-col>
                  <v-textarea
                    v-model="checkOutData.paymentDescription"
                    label="توضیحات پرداخت"
                    outlined
                    dense
                    class="mt-4"
                  >
                    <template v-slot:label>
                      <div class="d-flex flex-row c-purple">
                        <span class="red--text pe-1">*</span> توضیحات پرداخت
                      </div>
                    </template></v-textarea
                  >
                </v-col>
              </v-row>

              <hr class="my-5" />
              <v-row class="file-div mt-3 me-1 ms-1">
                <v-col class="pt-0">
                  <v-textarea
                    v-model="checkOutData.checkOutFileDescription"
                    label="توضیحات فایل تعهد‌نامه"
                    outlined
                    dense
                    class="mt-4"
                  >
                  </v-textarea>
                  <v-file-input
                    @change="newUpload(file)"
                    v-model="file"
                    outlined
                    dense
                    prepend-inner-icon="upload_file"
                    prepend-icon=""
                    class="file-input"
                  >
                    <template v-slot:label>
                      <div class="d-flex flex-row c-purple">
                        <span class="red--text pe-1">*</span> فایل تعهد‌نامه
                        دریافت کلیه حق و حقوق
                      </div>
                    </template></v-file-input
                  >
                  <v-progress-linear
                    rounded
                    v-if="myFile.showProgress"
                    height="10"
                    :value="myFile.uploadPercentage"
                    class="mb-4 mt-0"
                  >
                  </v-progress-linear>
                  <v-row>
                    <div
                      v-if="myFile.isUploaded"
                      color="green"
                      class="mt-3 mb-1 pa-3"
                      style="font-size: 16px"
                    >
                      فایل با موفقیت بارگذاری شد.

                      <div class="mt-5">
                        <span class="h3">فایل بارگذاری شده : </span>
                        <a :href="checkOutData.fileUrl" target="_blank">
                          {{ checkoutFileName }}</a
                        >
                      </div>
                    </div>
                  </v-row>
                  <!--<v-radio-group v-model="hasDescription" row>
                    <v-radio
                      label="فایل تعهد‌نامه توضیحات دارد."
                      value="withDescription"
                      @change="
                        file = [];
                        myFile.isUploaded = false;
                        myFile.showProgress = false;
                        changedFile = false;
                        checkOutData.checkOutFileDescription = '';
                      "
                    ></v-radio>
                    <v-radio
                      label="فایل تعهد‌نامه توضیحات ندارد."
                      value="withoutDescription"
                      @change="
                        file = [];
                        myFile.isUploaded = false;
                        myFile.showProgress = false;
                        changedFile = false;
                        checkOutData.checkOutFileDescription = '';
                      "
                    ></v-radio>
                  </v-radio-group>
                  <div
                    class="file-div"
                    v-if="hasDescription == 'withDescription'"
                  >
                    <v-textarea
                      v-model="checkOutData.checkOutFileDescription"
                      outlined
                      dense
                      label="توضیحات فایل تعهد‌نامه"
                      class="mt-3"
                    >
                    </v-textarea>

                    <v-file-input
                      @change="newUpload(file)"
                      v-model="file"
                      outlined
                      dense
                      prepend-inner-icon="upload_file"
                      prepend-icon=""
                      class="file-input"
                      :disabled="!checkOutData.checkOutFileDescription"
                    >
                      <template v-slot:label>
                        <div class="d-flex flex-row c-purple">
                          <span class="red--text pe-1">*</span> فایل تعهد‌نامه
                          دریافت کلیه حق و حقوق
                        </div>
                      </template></v-file-input
                    >
                    <v-progress-linear
                      rounded
                      v-if="myFile.showProgress"
                      height="10"
                      :value="myFile.uploadPercentage"
                      class="mb-4 mt-0"
                    >
                    </v-progress-linear>
                    <v-row>
                      <div
                        v-if="myFile.isUploaded"
                        color="green"
                        class="mt-3 mb-1 pa-3"
                        style="font-size: 16px"
                      >
                        فایل با موفقیت بارگذاری شد.

                        <div class="mt-5">
                          <span class="h3">فایل بارگذاری شده : </span>
                          <a :href="checkOutData.fileUrl" target="_blank">
                            {{ checkoutFileName }}</a
                          >
                        </div>
                      </div>
                    </v-row>
                  </div>
                  <div v-if="hasDescription == 'withoutDescription'">
                    <v-file-input
                      @change="newUpload(file)"
                      v-model="file"
                      outlined
                      dense
                      prepend-inner-icon="upload_file"
                      prepend-icon=""
                      class="file-input"
                    >
                      <template v-slot:label>
                        <div class="d-flex flex-row c-purple">
                          <span class="red--text pe-1">*</span> فایل تعهد‌نامه
                          دریافت کلیه حق و حقوق
                        </div>
                      </template></v-file-input
                    >
                    <v-progress-linear
                      rounded
                      v-if="myFile.showProgress"
                      height="10"
                      :value="myFile.uploadPercentage"
                      class="mb-4 mt-0"
                    >
                    </v-progress-linear>
                    <v-row>
                      <div
                        v-if="myFile.isUploaded"
                        color="green"
                        class="mt-3 mb-1 pa-3"
                        style="font-size: 16px"
                      >
                        فایل با موفقیت بارگذاری شد.

                        <div class="mt-5">
                          <span class="h3">فایل بارگذاری شده : </span>
                          <a :href="checkOutData.fileUrl" target="_blank">
                            {{ checkoutFileName }}</a
                          >
                        </div>
                      </div>
                    </v-row>
                  </div>-->
                </v-col>
              </v-row>

              <v-btn
                class="float-end red-btn mt-7 mb-4 submit-btn"
                @click="
                  makeCheckOut = false;
                  (checkOutData = []),
                    (file = []),
                    (myFile.isUploaded = false),
                    (myFile.showProgress = false);
                  (paymentFile = []),
                    (paymentMyFile.paymentIsUploaded = false),
                    (paymentMyFile.paymentShowProgress = false);
                  paymentUploadedFiles = [];
                "
                >لغو</v-btn
              >
              <v-btn
                class="float-end primary-btn mt-7 mb-4 mx-2 submit-btn"
                @click="
                  addCheckOut();

                  (file = []),
                    (myFile.isUploaded = false),
                    (myFile.showProgress = false);
                  (paymentFile = []),
                    (paymentMyFile.paymentIsUploaded = false),
                    (paymentMyFile.paymentShowProgress = false);
                  paymentUploadedFiles = [];
                "
                :disabled="computedDisabled"
                >ثبت</v-btn
              >
            </div>
          </div>
        </div>

        <!-- if the employee has a checkout form , admin can see it and if needed edit it-->
        <div v-if="checkOut.isCheckOut == true">
          <div style="background-color: transparent !important">
            <v-row>
              <v-col v-if="!edited">
                <v-col class="px-0">
                  <h3>فرم تسویه حساب</h3>
                </v-col>
                <v-btn
                  class="secondary-btn ms-2"
                  @click="printEmployeeCheckOut()"
                  style="float: left"
                >
                  <v-icon left>mdi-printer</v-icon>
                  <h6 class="mt-1">پرینت</h6>
                </v-btn>
                <v-btn
                  class="primary-btn"
                  style="float: left"
                  @click="
                    edited = true;
                    checkOutData = JSON.parse(JSON.stringify(checkOut));
                    emptyNewPayment();
                    paymentUploadedFiles = [];
                    myFile.showProgress = false;
                    paymentMyFile.paymentShowProgress = false;
                  "
                >
                  <v-icon left>edit</v-icon>
                  <h6 class="mt-1">ویرایش</h6>
                </v-btn>
              </v-col>
            </v-row>
          </div>

          <div v-if="!edited">
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <div class="disabled-fields grey lighten-3">
                  <span class="h3">
                    تاریخ شروع خدمت در شرکت:
                    {{
                      checkOut.startWorkingDate
                        ? checkOut.startWorkingDate
                        : "-"
                    }}</span
                  >
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <div class="disabled-fields grey lighten-3">
                  <span class="h3">
                    تاریخ تسویه حساب سوابق خدمتی:
                    {{
                      checkOut.checkOutDate ? checkOut.checkOutDate : "-"
                    }}</span
                  >
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <div class="disabled-fields grey lighten-3">
                  <span class="h3">
                    جمع سوابق خدمات:
                    {{
                      checkOut.serviceRecordsTotal
                        ? checkOut.serviceRecordsTotal
                        : "-"
                    }}</span
                  >
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <div class="disabled-fields grey lighten-3">
                  <span class="h3">
                    مانده مطالبات مرخصی به‌روز:
                    {{
                      checkOut.leaveDemandsNum ? checkOut.leaveDemandsNum : "-"
                    }}</span
                  >
                </div>
              </v-col>
            </v-row>
            <hr class="my-5" />
            <v-row>
              <v-col>
                <h5 class="blue-grey--text text--darken-2">
                  حقوق و مزایای ثابت دریافتی طی سال
                </h5>
                <div class="w-100 checkout-table">
                  <b-table
                    responsive
                    :fields="salaryAndBenefitsFields"
                    :items="checkOut.salaryAndBenefits"
                    class="mt-5"
                    bordered
                    small
                    empty-text="موردی برای نمایش وجود ندارد"
                    empty-filtered-text="موردی برای نمایش وجود ندارد"
                    show-empty
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div
                        class="text-center"
                        style="text-align: center; vertical-align: middle"
                      >
                        {{ data.value ? data.value : "-" }}
                      </div>
                    </template>
                    <template v-slot:cell(title)="data">
                      <div
                        class="text-center"
                        style="text-align: center; vertical-align: middle"
                      >
                        {{
                          faSalaryAndBenefits.filter(
                            (x) => x.value == data.value
                          )[0].text
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(value)="data">
                      <div
                        class="text-center"
                        style="text-align: center; vertical-align: middle"
                      >
                        {{
                          data.value
                            ? Number(data.value).toLocaleString() + " ریال"
                            : "-"
                        }}
                      </div>
                    </template>

                    <!--<template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>-->
                  </b-table>
                  <div class="mt-5 d-flex px-3">
                    <h5 class="primary--text font-weight-bold">
                      جمع حقوق و مزایا:
                    </h5>
                    <h5 class="ms-1">
                      {{
                        Number(
                          checkOut.salaryAndBenefitsTotal
                        ).toLocaleString() + " ریال "
                      }}
                    </h5>
                  </div>
                </div>
              </v-col>
            </v-row>
            <hr class="my-5" />
            <v-row>
              <v-col>
                <h5 class="blue-grey--text text--darken-2">شرح مطالبات</h5>
                <div class="w-100 checkout-table">
                  <b-table
                    responsive
                    :fields="demandsFields"
                    :items="checkOut.demands"
                    class="mt-5"
                    bordered
                    small
                    empty-text="موردی برای نمایش وجود ندارد"
                    empty-filtered-text="موردی برای نمایش وجود ندارد"
                    show-empty
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div
                        class="text-center"
                        style="text-align: center; vertical-align: middle"
                      >
                        {{ data.value ? data.value : "-" }}
                      </div>
                    </template>
                    <template v-slot:cell(title)="data">
                      <div
                        class="text-center"
                        style="text-align: center; vertical-align: middle"
                      >
                        {{
                          faDemands.filter((x) => x.value == data.value)[0].text
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(description)="data">
                      <div
                        class="text-center mx-auto"
                        style="
                          text-align: center;
                          vertical-align: middle;
                          max-width: 500px;
                        "
                      >
                        {{ data.value ? data.value : "-" }}
                      </div>
                    </template>
                    <template v-slot:cell(value)="data">
                      <div
                        class="text-center"
                        style="text-align: center; vertical-align: middle"
                      >
                        {{
                          data.value
                            ? Number(data.value).toLocaleString() + " ریال "
                            : "-"
                        }}
                      </div>
                    </template>
                    <!--<template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>-->
                  </b-table>
                  <div class="mt-5 d-flex px-3">
                    <h5 class="primary--text font-weight-bold">جمع مطالبات:</h5>
                    <h5 class="ms-1">
                      {{
                        checkOut.demandsTotal
                          ? Number(checkOut.demandsTotal).toLocaleString() +
                            " ریال "
                          : "-"
                      }}
                    </h5>
                  </div>
                </div>
              </v-col>
            </v-row>
            <hr class="my-5" />
            <v-row>
              <v-col>
                <h5 class="blue-grey--text text--darken-2">شرح کسورات</h5>
                <div class="w-100 checkout-table">
                  <b-table
                    responsive
                    :fields="deductionsFields"
                    :items="checkOut.deductions"
                    class="mt-5"
                    bordered
                    small
                    empty-text="موردی برای نمایش وجود ندارد"
                    empty-filtered-text="موردی برای نمایش وجود ندارد"
                    show-empty
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div
                        class="text-center"
                        style="text-align: center; vertical-align: middle"
                      >
                        {{ data.value ? data.value : "-" }}
                      </div>
                    </template>
                    <template v-slot:cell(value)="data">
                      <div
                        class="text-center"
                        style="text-align: center; vertical-align: middle"
                      >
                        {{
                          data.value
                            ? Number(data.value).toLocaleString() + " ریال"
                            : "-"
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(title)="data">
                      <div
                        class="text-center"
                        style="text-align: center; vertical-align: middle"
                      >
                        {{
                          faDeductions.filter((x) => x.value == data.value)[0]
                            .text
                        }}
                      </div>
                    </template>
                    <!--<template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>-->
                  </b-table>
                  <div class="mt-5 d-flex px-3">
                    <h5 class="primary--text font-weight-bold">جمع کسورات:</h5>
                    <h5 class="ms-1">
                      {{
                        checkOut.deductionsTotal
                          ? Number(checkOut.deductionsTotal).toLocaleString() +
                            " ریال "
                          : "-"
                      }}
                    </h5>
                  </div>
                  <!--<hr />-->
                  <!--<div class="mt-5 d-flex px-3">
                    <h5 class="primary--text font-weight-bold">
                      خالص مبلغ دریافتی محاسبه شده :
                    </h5>
                    <h6 class="ms-1">
                      {{
                        checkOut.checkOutAmount
                          ? checkOut.checkOutAmount >= 0
                            ? Number(checkOut.checkOutAmount).toLocaleString() +
                              " ریال "
                            : "مبلغ محاسبه شده منفی است."
                          : "-"
                      }}
                    </h6>
                  </div>
                  <v-row class="mt-1 px-3">
                    <v-col cols="10" class="d-flex">
                      <div class="me-4 mt-2 d-flex">
                        <h5 class="primary--text font-weight-bold">
                          خالص مبلغ دریافتی موردنظر (ریال):
                        </h5>
                        <h6 class="ms-1">
                          {{
                            checkOut.paymentAmount
                              ? checkOut.paymentAmount >= 0
                                ? Number(
                                    checkOut.paymentAmount
                                  ).toLocaleString() + " ریال "
                                : "مبلغ محاسبه شده منفی است."
                              : "-"
                          }}
                        </h6>
                      </div>
                    </v-col>
                  </v-row>
                  <div class="mt-5 d-flex px-3">
                    <h5 class="primary--text font-weight-bold">
                      مبلغ باقی‌مانده :
                    </h5>
                    <h6 class="ms-1">
                      {{
                        checkOut.checkOutAmount
                          ? checkOut.checkOutAmount >= 0
                            ? Number(
                                checkOut.checkOutRemainingAmount
                              ).toLocaleString() + " ریال "
                            : "مبلغ محاسبه شده منفی است."
                          : "-"
                      }}
                    </h6>
                  </div>-->
                </div>
              </v-col>
            </v-row>
            <hr class="my-5" />

            <v-row class="time-row">
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <div class="disabled-fields grey lighten-3">
                  <span class="h3">
                    تنظیم کننده:
                    {{ checkOut.regulator ? checkOut.regulator : "-" }}</span
                  >
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <div class="disabled-fields grey lighten-3">
                  <span class="h3">
                    تایید کننده:
                    {{ checkOut.confirmer ? checkOut.confirmer : "-" }}</span
                  >
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <div class="disabled-fields grey lighten-3">
                  <span class="h3">
                    تصویب کننده:
                    {{ checkOut.approver ? checkOut.approver : "-" }}</span
                  >
                </div>
              </v-col>
            </v-row>
            <hr class="my-5" />
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <div class="disabled-fields grey lighten-3">
                  <span class="h3">
                    مبلغ کل تسویه حساب:
                    {{
                      checkOut.checkOutAmount
                        ? Number(checkOut.checkOutAmount).toLocaleString() +
                          " ریال"
                        : "0"
                    }}
                  </span>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <div class="disabled-fields grey lighten-3">
                  <span class="h3">
                    مبلغ باقی‌مانده:
                    {{
                      checkOut.checkOutRemainingAmount
                        ? Number(
                            checkOut.checkOutRemainingAmount
                          ).toLocaleString() + " ریال"
                        : "0"
                    }}</span
                  >
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div
                  style="max-height: 600px; overflow-y: scroll"
                  class="disabled-fields grey lighten-3"
                >
                  <div class="mb-3">
                    <span class="h3"> لیست پرداخت‌ها: </span>
                  </div>
                  <div
                    class="px-3 py-1"
                    v-for="(payment, index) in checkOut.checkOutFinancials"
                    :key="index"
                  >
                    <span class="h3 primary--text">
                      پرداخت شماره <strong> {{ index + 1 }} </strong></span
                    >
                    <hr class="w-100" />
                    <v-row class="mt-1">
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><span class="h3 mt-2 px-2">
                          نحوه پرداخت: <span>{{ payment.transferType }}</span>
                        </span></v-col
                      >
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                        ><span class="h3 mt-2 px-2">
                          مبلغ پرداخت :
                          <span>{{
                            payment.amount
                              ? Number(payment.amount).toLocaleString()
                              : "-"
                          }}</span>
                          ریال
                        </span></v-col
                      >
                    </v-row>
                    <div class="mt-1" v-if="payment.transferType == 'کارت'">
                      <v-row>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                          ><span class="h3 mt-2 px-2">
                            شماره کارت فرستنده:
                            <span dir="rtl">{{
                              cardNumberSpacer(payment.cardNumberSender)
                            }}</span>
                          </span></v-col
                        >

                        <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                          ><span class="h3 mt-2 px-2">
                            شماره کارت گیرنده:
                            <span dir="rtl">{{
                              cardNumberSpacer(payment.cardNumberReciever)
                            }}</span>
                          </span></v-col
                        >
                      </v-row>
                    </div>

                    <div class="mt-2" v-if="payment.transferType == 'چک'">
                      <v-row>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <span class="h3 mt-2 px-2">
                            شماره چک:
                            <span>{{ payment.chequeNumber }}</span>
                          </span>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <span class="h3 mt-2 px-2">
                            شماره صیاد :
                            <span>{{ payment.chequeSayyad }}</span>
                          </span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <span class="h3 mt-2 px-2">
                            نام بانک:
                            <span>{{ payment.chequeBankName }}</span>
                          </span>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <span class="h3 mt-2 px-2">
                            تاریخ چک:
                            <span>{{ payment.chequeDueDate }}</span>
                          </span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <span class="h3 mt-2 px-2">
                            وضعیت چک:
                            <span>{{ payment.chequeStatus }}</span>
                          </span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <span class="h3 mt-2 px-2">
                            توضیحات چک :
                            <div
                              class="px-3"
                              style="line-height: 28px; text-align: justify"
                            >
                              {{ payment.chequeDescription }}
                            </div>
                          </span>
                        </v-col>
                      </v-row>
                    </div>
                    <v-row
                      class="mt-1"
                      v-if="
                        payment.transferType == 'کارت' ||
                        payment.transferType == 'چک'
                      "
                    >
                      <v-col>
                        <span class="h3 mt-2 px-2">
                          کارمزد انتقال وجه :<span>{{
                            Number(payment.transactionFee).toLocaleString() +
                            "ریال"
                          }}</span>
                        </span>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <span class="h3 mx-2 mt-2">
                          فایل‌های مربوط به پرداخت ({{
                            payment.PaymentFiles
                              ? payment.PaymentFiles.length
                              : "0"
                          }}) :
                        </span>
                        <div
                          class="pa-3"
                          v-if="
                            payment.PaymentFiles && payment.PaymentFiles.length
                          "
                        >
                          <li
                            v-for="(item, index) in payment.PaymentFiles"
                            :key="index"
                            class="h4 mt-4"
                          >
                            <a :href="item.url" target="_blank">
                              {{ item.name }}</a
                            >
                          </li>
                        </div>
                        <span v-else class="h3">فایلی آپلود نشده است.</span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <span class="h3 px-2">
                          توضیحات پرداخت :
                          <div
                            class="px-3"
                            style="line-height: 28px; text-align: justify"
                          >
                            {{
                              payment.description ? payment.description : "-"
                            }}
                          </div>
                        </span></v-col
                      >
                    </v-row>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="disabled-fields grey lighten-3">
                  <div class="mt-2">
                    <span class="h3">
                      فایل تعهد‌نامه دریافت کلیه حق و حقوق {{ userData.name }}:
                      <a
                        :href="checkOut.checkOutFileUrl"
                        target="_blank"
                        v-if="checkOut.checkOutFileUrl"
                      >
                        <span class="font-weight-bold">دریافت</span>
                      </a>
                      <span v-else>فایل آپلود نشده است.</span>
                    </span>
                  </div>

                  <hr class="my-5" />
                  <div class="mt-4">
                    <span class="h3"> توضیحات فایل تعهد‌نامه: </span>
                    <div
                      class="h3 pa-2"
                      style="line-height: 22px; text-align: justify"
                    >
                      {{
                        checkOut.checkOutFileDescription
                          ? checkOut.checkOutFileDescription
                          : "توضیحی ثبت نشده است."
                      }}
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
          <div v-else>
            <v-col class="my-2 px-0">
              <h3>ویرایش فرم تسویه حساب</h3>
            </v-col>

            <v-row class="time-row mt-3">
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <span id="startWorkingDate1">
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    append-icon="calendar_today"
                    v-model="checkOutData.startWorkingDate"
                    :editable="true"
                    class="date-input"
                  >
                    <template v-slot:label>
                      <div class="d-flex flex-row c-purple">
                        <span class="red--text pe-1">*</span> تاریخ شروع خدمت در
                        شرکت
                      </div>
                    </template>
                  </v-text-field>
                </span>
                <date-picker
                  v-model="checkOutData.startWorkingDate"
                  element="startWorkingDate1"
                  color="#00a7b7"
                />
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <span id="checkOutDate2">
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    append-icon="calendar_today"
                    v-model="checkOutData.checkOutDate"
                    :editable="true"
                    class="date-input"
                  >
                    <template v-slot:label>
                      <div class="d-flex flex-row c-purple">
                        <span class="red--text pe-1">*</span> تاریخ تسویه حساب
                        سوابق خدمتی
                      </div>
                    </template>
                  </v-text-field>
                </span>
                <date-picker
                  v-model="checkOutData.checkOutDate"
                  element="checkOutDate2"
                  color="#00a7b7"
                />
              </v-col>
            </v-row>
            <v-row class="time-row">
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-text-field
                  label="جمع سوابق خدمات"
                  outlined
                  dense
                  v-model="checkOutData.serviceRecordsTotal"
                  type="number"
                >
                  <template v-slot:label>
                    <div class="d-flex flex-row c-purple">
                      <span class="red--text pe-1">*</span>
                      جمع سوابق خدمات
                    </div>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-text-field
                  label="مانده مطالبات مرخصی به‌روز"
                  outlined
                  dense
                  v-model="checkOutData.leaveDemandsNum"
                  type="number"
                >
                  <template v-slot:label>
                    <div class="d-flex flex-row c-purple">
                      <span class="red--text pe-1">*</span>
                      مانده مطالبات مرخصی به‌روز
                    </div>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h5 class="blue-grey--text text--darken-2">
                  <span class="red--text pe-1">*</span>
                  حقوق و مزایای ثابت دریافتی طی سال
                </h5>
                <div class="w-100 checkout-table">
                  <b-table
                    responsive
                    :fields="salaryAndBenefitsFields"
                    :items="checkOutData.salaryAndBenefits"
                    class="mt-5"
                    bordered
                    small
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell(title)="data">
                      <div
                        class="text-center"
                        style="text-align: center; vertical-align: middle"
                      >
                        {{
                          faSalaryAndBenefits.filter(
                            (x) => x.value == data.value
                          )[0].text
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(value)="data">
                      <div
                        class="d-flex justify-space-between"
                        style="text-align: center; vertical-align: middle"
                      >
                        <vuetify-money
                          v-model="data.item.value"
                          :valueWhenIsEmpty="whenIsEmpty"
                          :options="vMoneyOptions"
                          type="number"
                          class="w-100 me-2"
                          outlined
                          dense
                        ></vuetify-money>
                        ریال
                      </div>
                    </template>
                    <!--<template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>-->
                  </b-table>
                  <div class="mt-5 d-flex px-3">
                    <h5 class="primary--text font-weight-bold">
                      جمع حقوق و مزایا:
                    </h5>
                    <h5 class="ms-1">
                      {{
                        computedBenefitsTotal
                          ? Number(computedBenefitsTotal).toLocaleString() +
                            " ریال "
                          : "-"
                      }}
                    </h5>
                  </div>
                </div>
              </v-col>
            </v-row>
            <hr />

            <v-row>
              <v-col>
                <h5 class="blue-grey--text text--darken-2">
                  <span class="red--text pe-1">*</span>
                  شرح مطالبات
                </h5>
                <div class="w-100 checkout-table">
                  <b-table
                    responsive
                    :fields="demandsFields"
                    :items="checkOutData.demands"
                    class="mt-5"
                    bordered
                    small
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell(title)="data">
                      <div
                        class="text-center"
                        style="text-align: center; vertical-align: middle"
                      >
                        {{
                          faDemands.filter((x) => x.value == data.value)[0].text
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(value)="data">
                      <div
                        class="d-flex justify-space-between"
                        style="text-align: center; vertical-align: middle"
                      >
                        <vuetify-money
                          v-model="data.item.value"
                          :valueWhenIsEmpty="whenIsEmpty"
                          :options="vMoneyOptions"
                          type="number"
                          class="w-100 me-2"
                          outlined
                          dense
                        ></vuetify-money>
                        ریال
                      </div>
                    </template>
                    <template v-slot:cell(duration)="data">
                      <div
                        class="d-flex justify-space-between"
                        style="text-align: center; vertical-align: middle"
                      >
                        <v-text-field
                          outlined
                          dense
                          v-model="data.item.duration"
                        ></v-text-field>
                      </div>
                    </template>
                    <template v-slot:cell(description)="data">
                      <div
                        class="d-flex justify-space-between"
                        style="text-align: center; vertical-align: middle"
                      >
                        <v-textarea
                          v-model="data.item.description"
                          outlined
                          rows="1"
                          dense
                        ></v-textarea>
                      </div>
                    </template>
                    <!--<template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>-->
                  </b-table>
                  <div class="mt-5 d-flex px-3">
                    <h5 class="primary--text font-weight-bold">جمع مطالبات:</h5>
                    <h5 class="ms-1">
                      {{
                        computedDemandsTotal
                          ? Number(computedDemandsTotal).toLocaleString() +
                            " ریال "
                          : "-"
                      }}
                    </h5>
                  </div>
                </div>
              </v-col>
            </v-row>
            <hr />

            <v-row>
              <v-col>
                <h5 class="blue-grey--text text--darken-2">
                  <span class="red--text pe-1">*</span>
                  شرح کسورات
                </h5>
                <div class="w-100 checkout-table">
                  <b-table
                    responsive
                    :fields="deductionsFields"
                    :items="checkOutData.deductions"
                    class="mt-5"
                    bordered
                    small
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell(title)="data">
                      <div
                        class="text-center"
                        style="text-align: center; vertical-align: middle"
                      >
                        {{
                          faDeductions.filter((x) => x.value == data.value)[0]
                            .text
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(value)="data">
                      <div
                        class="d-flex justify-space-between"
                        style="text-align: center; vertical-align: middle"
                      >
                        <vuetify-money
                          v-model="data.item.value"
                          :valueWhenIsEmpty="whenIsEmpty"
                          :options="vMoneyOptions"
                          type="number"
                          class="w-100 me-2"
                          outlined
                          dense
                        ></vuetify-money>
                        ریال
                      </div>
                    </template>
                    <!--<template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>-->
                  </b-table>
                  <div class="mt-5 d-flex px-3">
                    <h5 class="primary--text font-weight-bold">جمع کسورات:</h5>
                    <h5 class="ms-1">
                      {{
                        computedDeductionsTotal
                          ? Number(computedDeductionsTotal).toLocaleString() +
                            " ریال "
                          : "-"
                      }}
                    </h5>
                  </div>
                  <hr />
                  <div class="mt-5 d-flex px-3">
                    <h5 class="primary--text font-weight-bold">
                      خالص مبلغ دریافتی محاسبه شده :
                    </h5>
                    <h5 class="ms-1">
                      {{
                        computedDemandsTotal
                          ? computedDemandsTotal - computedDeductionsTotal >= 0
                            ? Number(
                                computedDemandsTotal - computedDeductionsTotal
                              ).toLocaleString() + " ریال "
                            : "مبلغ محاسبه شده منفی است."
                          : "-"
                      }}
                    </h5>
                  </div>
                  <!--<v-row class="mt-5 px-3">
                    <v-col cols="10" class="d-flex">
                      <div class="me-4 mt-2">
                        <h5 class="primary--text font-weight-bold">
                          خالص مبلغ دریافتی موردنظر (ریال):
                        </h5>
                      </div>
                      <div class="w-50">
                        <vuetify-money
                          v-model="newPayment.paymentAmount"
                          :valueWhenIsEmpty="whenIsEmpty"
                          :options="vMoneyOptions"
                          type="number"
                          class="me-2"
                          outlined
                          dense
                        ></vuetify-money>
                      </div>
                    </v-col>
                  </v-row>-->
                </div>
              </v-col>
            </v-row>
            <hr class="mb-3" />
            <v-row class="time-row">
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-text-field
                  label="تنظیم کننده "
                  outlined
                  dense
                  v-model="checkOutData.regulator"
                >
                  <template v-slot:label>
                    <div class="d-flex flex-row c-purple">
                      <span class="red--text pe-1">*</span>
                      تنظیم کننده
                    </div>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-text-field
                  label="تایید کننده "
                  outlined
                  dense
                  v-model="checkOutData.confirmer"
                >
                  <template v-slot:label>
                    <div class="d-flex flex-row c-purple">
                      <span class="red--text pe-1">*</span>
                      تایید کننده
                    </div>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-text-field
                  label="تصویب کننده "
                  outlined
                  dense
                  v-model="checkOutData.approver"
                >
                  <template v-slot:label>
                    <div class="d-flex flex-row c-purple">
                      <span class="red--text pe-1">*</span>
                      تصویب کننده
                    </div>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <hr />
            <!-- inja -->

            <h5 class="blue-grey--text text--darken-2">پرداخت تسویه</h5>
            <div class="mt-5 px-2">
              <div class="d-flex">
                <li class="me-2 h2 primary--text font-weight-bold">
                  مبلغ کل تسویه حساب (﷼):
                </li>
                <h6>
                  {{
                    computedDemandsTotal
                      ? computedDemandsTotal - computedDeductionsTotal > 0
                        ? Number(
                            computedDemandsTotal - computedDeductionsTotal
                          ).toLocaleString() + " ریال "
                        : "مبلغ محاسبه شده منفی است."
                      : "-"
                  }}
                </h6>
              </div>
              <!--<div class="mt-2 d-flex">
                <li
                  class="me-2 h2 font-weight-bold"
                  :class="
                    Number(computedPaymentAmount) >
                    Number(computedDemandsTotal) -
                      Number(computedDeductionsTotal)
                      ? 'red--text'
                      : 'primary--text'
                  "
                >
                  مجموع مبلغ پرداختی (﷼):
                </li>
                <h6>
                  {{ computedPaymentAmount.toLocaleString() + " ریال" }}
                </h6>
              </div>-->
              <!--<div class="mt-2 d-flex">
                <li class="me-2 h2 primary--text font-weight-bold">
                   مبلغ باقی‌مانده (﷼):
                </li>
                <h6>
                  {{ Number(computedRemainingAmount).toLocaleString() + ' ریال' }}
                </h6>
              </div>-->
            </div>
            <!--<v-row class="time-row">
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <vuetify-money
                  v-model="checkOutData.checkOutAmount"
                  :valueWhenIsEmpty="whenIsEmpty"
                  :options="vMoneyOptions"
                  label=" مبلغ کل تسویه حساب(﷼) *"
                  type="number"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <div class="disabled-fields grey lighten-3">
                  <span class="h3">
                    مبلغ باقی‌مانده:
                    {{
                      checkOutData.checkOutRemainingAmount
                        ? Number(
                            checkOutData.checkOutRemainingAmount
                          ).toLocaleString() + "ریال"
                        : "0"
                    }}</span
                  >
                </div>
              </v-col>
            </v-row>-->
            <!-- add a new type of payment -->
            <div class="mt-3">
              <v-checkbox
                v-model="newPaymentCheckBox"
                color="primary"
                v-if="checkOutData.checkOutRemainingAmount != '0'"
              >
                <template v-slot:label>
                  <span class="mt-2">ثبت پرداخت جدید</span>
                </template>
              </v-checkbox>
              <div v-if="newPaymentCheckBox">
                <v-row class="time-row">
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-select
                      v-model="newPayment.transferType"
                      :items="transferTypes"
                      item-text="text"
                      item-value="value"
                      label="نحوه پرداخت"
                      outlined
                      dense
                    >
                      <template v-slot:label>
                        <div class="d-flex flex-row c-purple">
                          <span class="red--text pe-1">*</span>نحوه پرداخت
                        </div>
                      </template></v-select
                    >
                  </v-col>

                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <vuetify-money
                      v-model="newPayment.paymentAmount"
                      :valueWhenIsEmpty="whenIsEmpty"
                      :options="vMoneyOptions"
                      label=" مبلغ پرداخت(﷼) *"
                      type="number"
                      outlined
                      dense
                    />
                    <span
                      class="red--text h4"
                      v-if="
                        computedDemandsTotal - computedDeductionsTotal <
                        newPayment.paymentAmount
                      "
                      >*مبلغ وارد شده نباید بیشتر از مبلغ محاسبه شده باشد</span
                    >
                  </v-col>
                </v-row>
                <v-row
                  v-if="newPayment.transferType == 'card'"
                  class="time-row"
                >
                  <v-col class="pb-0">
                    <v-row class="time-row">
                      <v-col
                        cols="12"
                        sm="12"
                        md="6"
                        lg="6"
                        xl="6"
                        class="pb-0"
                      >
                        <v-text-field
                          label="شماره کارت فرستنده"
                          outlined
                          dense
                          v-model="newPayment.cardNumberSender"
                          type="number"
                          @wheel="$event.target.blur()"
                        >
                          <template v-slot:label>
                            <div class="d-flex flex-row c-purple">
                              <span class="red--text pe-1">*</span> شماره کارت
                              فرستنده
                            </div>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="6"
                        lg="6"
                        xl="6"
                        class="pb-0"
                      >
                        <v-text-field
                          label="شماره کارت گیرنده"
                          outlined
                          dense
                          v-model="newPayment.cardNumberReciever"
                          type="number"
                          @wheel="$event.target.blur()"
                        >
                          <template v-slot:label>
                            <div class="d-flex flex-row c-purple">
                              <span class="red--text pe-1">*</span> شماره کارت
                              گیرنده
                            </div>
                          </template>
                        </v-text-field></v-col
                      >
                    </v-row>
                  </v-col>
                </v-row>
                <div
                  v-if="newPayment.transferType == 'cheque'"
                  class="time-row mt-4"
                >
                  <v-row>
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                      <v-text-field
                        label="شماره چک"
                        outlined
                        dense
                        v-model="newPayment.chequeNumber"
                        type="text"
                      >
                        <template v-slot:label>
                          <div class="d-flex flex-row c-purple">
                            <span class="red--text pe-1">*</span> شماره چک
                          </div>
                        </template></v-text-field
                      ></v-col
                    >
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                      <v-text-field
                        label="شناسه صیاد"
                        outlined
                        dense
                        v-model="newPayment.chequeSayyad"
                        type="text"
                      >
                        <template v-slot:label>
                          <div class="d-flex flex-row c-purple">
                            <span class="red--text pe-1">*</span> شناسه صیاد
                          </div>
                        </template></v-text-field
                      >
                    </v-col>
                  </v-row>
                  <v-row class="time-row">
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                      <v-text-field
                        label="نام بانک"
                        outlined
                        dense
                        v-model="newPayment.chequeBankName"
                        type="text"
                      >
                        <template v-slot:label>
                          <div class="d-flex flex-row c-purple">
                            <span class="red--text pe-1">*</span> نام بانک
                          </div>
                        </template></v-text-field
                      >
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                      <span id="chequeDueDate2">
                        <v-text-field
                          outlined
                          dense
                          type="text"
                          append-icon="schedule"
                          v-model="newPayment.chequeDueDate"
                          label="تاریخ سررسید"
                          :editable="true"
                          class="date-input"
                        >
                          <template v-slot:label>
                            <div class="d-flex flex-row c-purple">
                              <span class="red--text pe-1">*</span> تاریخ سررسید
                            </div>
                          </template>
                        </v-text-field>
                      </span>

                      <date-picker
                        v-model="newPayment.chequeDueDate"
                        element="chequeDueDate2"
                        color="#00a7b7"
                        type="date"
                    /></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        outlined
                        dense
                        v-model="newPayment.chequeStatus"
                        :items="chequeStatuses"
                        item-text="text"
                        item-value="value"
                      >
                        <template v-slot:label>
                          <div class="d-flex flex-row c-purple">
                            <span class="red--text pe-1">*</span>وضعیت چک
                          </div>
                        </template></v-select
                      >
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-textarea
                        v-model="newPayment.chequeDescription"
                        outlined
                        dense
                        class="mt-1"
                      >
                        <template v-slot:label>
                          <div class="d-flex flex-row c-purple">
                            <span class="red--text pe-1">*</span>توضیحات چک
                          </div>
                        </template></v-textarea
                      >
                    </v-col>
                  </v-row>
                </div>
                <v-row
                  class="time-row"
                  :class="newPayment.transferType == 'card' ? 'mt-8' : 'mt-2'"
                  v-if="
                    newPayment.transferType == 'card' ||
                    newPayment.transferType == 'cheque'
                  "
                >
                  <v-col>
                    <vuetify-money
                      v-model="newPayment.transactionFee"
                      :valueWhenIsEmpty="whenIsEmpty"
                      :options="vMoneyOptions"
                      label="  *کارمزد انتقال وجه(﷼) "
                      type="number"
                      outlined
                      dense
                    />
                  </v-col>
                </v-row>
                <hr class="mt-4 mb-2" />
                <v-row
                  :class="newPayment.transferType == 'card' ? 'mt-3' : 'mt-0'"
                >
                  <v-col class="pt-0">
                    <v-file-input
                      @change="editPaymentUpload(paymentFile)"
                      v-model="paymentFile"
                      outlined
                      dense
                      prepend-inner-icon="upload_file"
                      prepend-icon=""
                      class="file-input payment-upload mt-3"
                      label="فایل مربوط به
                        پرداخت"
                    >
                    </v-file-input>
                    <v-progress-linear
                      rounded
                      v-if="paymentMyFile.paymentShowProgress"
                      height="10"
                      :value="paymentMyFile.paymentUploadPercentage"
                      class="mb-4 mt-0"
                    >
                    </v-progress-linear>
                    <v-row>
                      <div
                        v-if="paymentMyFile.paymentIsUploaded"
                        color="green"
                        class="mt-3 mb-1 pa-3"
                        style="font-size: 16px"
                      >
                        فایل با موفقیت بارگذاری شد.
                      </div>
                    </v-row>
                    <div
                      v-if="
                        newPaymentUploadedFiles &&
                        newPaymentUploadedFiles.length
                      "
                      class="files-box d-flex flex-column"
                    >
                      <div class="files-title">
                        لیست فایل‌های آپلود شده ({{
                          newPaymentUploadedFiles.length
                        }})
                      </div>

                      <span
                        v-for="(item, index) in newPaymentUploadedFiles"
                        :key="index"
                        class="mb-2 pt-2"
                      >
                        <span @click="deleteFile2(index)" class="pointer">
                          <v-icon class="red--text text--lighten-1 pe-2"
                            >mdi-close-circle-outline</v-icon
                          >
                        </span>
                        <a :href="item.url" target="blank">{{
                          showName2[index]
                        }}</a>
                      </span>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="time-row mt-1">
                  <v-col>
                    <v-textarea
                      v-model="newPayment.description"
                      label=""
                      outlined
                      dense
                      class="mt-2"
                    >
                      <template v-slot:label>
                        <div class="d-flex flex-row c-purple">
                          <span class="red--text pe-1">*</span>توضیحات پرداخت
                        </div>
                      </template></v-textarea
                    >
                  </v-col>
                </v-row>
                <hr />
              </div>

              <h5 class="primary--text font-weight-bold">لیست پرداختی‌ها</h5>
              <b-table
                responsive
                show-empty
                :fields="paymentsFields"
                :items="checkOutData.checkOutFinancials"
                empty-text="پرداختی ثبت نشده است"
                empty-filtered-text="پرداختی ثبت نشده است"
                :filter="Filter"
              >
                <template v-slot:head()="data">
                  <div style="text-align: center; vertical-align: middle">
                    {{ data.label }}
                  </div>
                </template>
                <template v-slot:cell(description)="data">
                  <div style="text-align: center; vertical-align: middle">
                    <v-tooltip
                      right
                      max-width="600"
                      v-if="deviceType != 'mobile'"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          {{
                            data.value.length > 20
                              ? data.value.slice(0, 20) + "..."
                              : data.value
                          }}
                        </span>
                      </template>
                      <p class="text-right p-2 text-justify">
                        {{ data.value }}
                      </p>
                    </v-tooltip>
                    <p v-else>
                      {{
                        data.value.length > 20
                          ? data.value.slice(0, 20) + "..."
                          : data.value
                      }}
                    </p>
                  </div>
                </template>
                <template v-slot:cell()="data">
                  <div style="text-align: center; vertical-align: middle">
                    {{ data.value }}
                  </div>
                </template>
                <template v-slot:cell(amount)="data">
                  <div style="text-align: center; vertical-align: middle">
                    {{ Number(data.value).toLocaleString() }}
                  </div>
                </template>
                <template v-slot:cell(operation)="data">
                  <div style="text-align: center; vertical-align: middle">
                    <v-btn
                      class="primary-btn pa-2"
                      @click="openModal(data.item)"
                      style="margin-top: 2px"
                      ><v-icon> mdi-file-eye-outline</v-icon>
                    </v-btn>
                  </div>
                </template>
                <template v-slot:cell(index)="data">
                  <div style="text-align: center; vertical-align: middle">
                    {{ data.index + 1 }}
                  </div>
                </template>
              </b-table>
            </div>

            <hr class="my-5" />
            <v-row>
              <v-col>
                <div class="file-div">
                  <v-textarea
                    v-model="checkOutData.checkOutFileDescription"
                    outlined
                    dense
                    label="توضیحات فایل تعهد‌نامه"
                  >
                  </v-textarea>
                  <div class="mb-5" v-if="checkOutData.checkOutFileId">
                    <span class="h3" v-if="!changedFile">
                      فایل تعهد‌نامه فعلی {{ userData.name }}:
                      <a
                        :href="checkOutData.checkOutFileUrl"
                        target="_blank"
                        color="primary"
                        dir="ltr"
                      >
                        دریافت
                      </a></span
                    >
                    <span class="h3" v-else
                      >فایل تعهد‌نامه فعلی :
                      <a :href="newFileUrl" target="_blank" color="primary">
                        {{ editChekoutFileName }}
                      </a></span
                    >
                  </div>

                  <v-file-input
                    @change="editUpload(file)"
                    v-model="file"
                    outlined
                    dense
                    prepend-inner-icon="upload_file"
                    prepend-icon=""
                    class="file-input"
                  >
                    <template v-slot:label>
                      <div class="d-flex flex-row c-purple">
                        <span class="red--text pe-1">*</span> فایل تعهد‌نامه
                        دریافت کلیه حق و حقوق
                      </div>
                    </template></v-file-input
                  >
                  <v-progress-linear
                    rounded
                    v-if="myFile.showProgress"
                    height="10"
                    :value="myFile.uploadPercentage"
                    class="mb-4 mt-0"
                  >
                  </v-progress-linear>
                  <v-row>
                    <div
                      v-if="myFile.isUploaded"
                      color="green"
                      class="mt-3 mb-1 pa-3"
                      style="font-size: 16px"
                    >
                      فایل با موفقیت بارگذاری شد.
                    </div>
                  </v-row>
                </div>
              </v-col>
            </v-row>

            <v-btn
              class="float-end red-btn mt-7 mb-4 submit-btn"
              @click="
                edited = false;
                (file = []),
                  (myFile.isUploaded = false),
                  (myFile.showProgress = false);
                (paymentFile = []),
                  (paymentMyFile.paymentIsUploaded = false),
                  (paymentMyFile.paymentShowProgress = false);
                paymentUploadedFiles = [];

                changedFile = false;
              "
              >لغو</v-btn
            >
            <v-btn
              class="float-end primary-btn mt-7 mb-4 mx-2 submit-btn"
              @click="
                editCheckOut();
                (file = []),
                  (myFile.isUploaded = false),
                  (myFile.showProgress = false);
                (paymentFile = []),
                  (paymentMyFile.paymentIsUploaded = false),
                  (paymentMyFile.paymentShowProgress = false);
                paymentUploadedFiles = [];
              "
              :disabled="computedDisabledForEdit"
              >ثبت</v-btn
            >
          </div>
        </div>
      </div>
    </v-col>
    <div id="employeeCheckOut" ref="employeeCheckOut" v-show="false">
      <h3 class="text-right mb-4">
        تسویه حساب
        {{ userData.name }}
      </h3>
      <v-row class="px-2 mb-4">
        <table width="100%" border="1px solid">
          <body>
            <tr>
              <th class="px-2 py-1">تاریخ شروع خدمت در شرکت</th>
              <td class="px-2 py-1">
                {{
                  checkOut.startWorkingDate ? checkOut.startWorkingDate : "-"
                }}
              </td>
              <th class="px-2 py-1">تاریخ تسویه حساب سوابق خدمتی</th>
              <td class="px-2 py-1">
                {{ checkOut.checkOutDate ? checkOut.checkOutDate : "-" }}
              </td>
            </tr>
            <tr>
              <th class="px-2 py-1">جمع سوابق خدمات</th>
              <td class="px-2 py-1">
                {{
                  checkOut.serviceRecordsTotal
                    ? checkOut.serviceRecordsTotal
                    : "-"
                }}
              </td>
              <th class="px-2 py-1">مانده مطالبات مرخصی به‌روز</th>
              <td class="px-2 py-1">
                {{ checkOut.leaveDemandsNum ? checkOut.leaveDemandsNum : "-" }}
              </td>
            </tr>
          </body>
        </table>
      </v-row>
      <hr />
      <div class="mt-3 mb-3">
        <h4>حقوق و مزایای ثابت دریافتی طی سال (ریال)</h4>
      </div>
      <v-row class="mb-4 px-2" style="break-inside: avoid !important">
        <table
          v-if="checkOut.salaryAndBenefits"
          width="100%"
          border="1px solid"
        >
          <body v-if="checkOut.salaryAndBenefits.length">
            <tr>
              <th class="px-2 py-1">حقوق ثابت</th>
              <td class="px-2 py-1">
                {{
                  checkOut.salaryAndBenefits[0].value
                    ? Number(
                        checkOut.salaryAndBenefits[0].value
                      ).toLocaleString()
                    : "-"
                }}
              </td>
              <th class="px-2 py-1">حق خواروبار</th>
              <td class="px-2 py-1">
                {{
                  checkOut.salaryAndBenefits[1].value
                    ? Number(
                        checkOut.salaryAndBenefits[1].value
                      ).toLocaleString()
                    : "-"
                }}
              </td>
            </tr>
            <tr>
              <th class="px-2 py-1">حق مسکن</th>
              <td class="px-2 py-1">
                {{
                  checkOut.salaryAndBenefits[2].value
                    ? Number(
                        checkOut.salaryAndBenefits[2].value
                      ).toLocaleString()
                    : "-"
                }}
              </td>
              <th class="px-2 py-1">حق اولاد</th>
              <td class="px-2 py-1">
                {{
                  checkOut.salaryAndBenefits[3].value
                    ? Number(
                        checkOut.salaryAndBenefits[3].value
                      ).toLocaleString()
                    : "-"
                }}
              </td>
            </tr>
            <tr>
              <th class="px-2 py-1">جمع حقوق و مزایا</th>
              <td class="px-2 py-1" colspan="1">
                {{
                  checkOut.salaryAndBenefitsTotal
                    ? Number(checkOut.salaryAndBenefitsTotal).toLocaleString()
                    : "-"
                }}
                ریال
              </td>
            </tr>
          </body>
        </table>
      </v-row>
      <hr />
      <div class="mt-3 mb-3"><h4>شرح مطالبات</h4></div>
      <v-row class="mb-4 px-2" style="break-inside: avoid !important">
        <table width="100%" border="1px solid" v-if="checkOut.demands">
          <head>
            <th class="px-2 py-1">عنوان</th>
            <th class="px-2 py-1">مدت</th>
            <th class="px-2 py-1">مقدار (ریال)</th>
            <th class="px-2 py-1">شرح</th>
          </head>
          <body v-if="checkOut.demands.length">
            <tr v-for="item in checkOut.demands" :key="item.title">
              <th class="px-2 py-1">
                {{ faDemands.filter((x) => x.value == item.title)[0].text }}
              </th>
              <td class="px-2 py-1">
                {{ item.duration ? item.duration : "-" }}
              </td>
              <td class="px-2 py-1">
                {{ item.value ? Number(item.value).toLocaleString() : "-" }}
              </td>
              <td class="px-2 py-1">
                {{ item.description ? item.description : "-" }}
              </td>
            </tr>
            <tr>
              <th class="px-2 py-1">جمع مطالبات</th>
              <td class="px-3 py-1" colspan="2">
                {{
                  checkOut.demandsTotal
                    ? Number(checkOut.demandsTotal).toLocaleString()
                    : "-"
                }}
                ریال
              </td>
            </tr>
          </body>
        </table>
      </v-row>
      <hr />
      <div class="mt-3 mb-3"><h4>شرح کسورات (ریال)</h4></div>
      <v-row class="mb-4 px-2" style="break-inside: avoid !important">
        <table width="100%" border="1px solid" v-if="checkOut.deductions">
          <body v-if="checkOut.deductions.length">
            <tr>
              <th class="px-2 py-1">مالیات حقوق و عیدی ( مالیات مرخصی )</th>
              <td class="px-2 py-1">
                {{
                  checkOut.deductions[0].value
                    ? Number(checkOut.deductions[0].value).toLocaleString()
                    : "-"
                }}
              </td>
              <th class="px-2 py-1">بیمه سهم کارمند</th>
              <td class="px-2 py-1">
                {{
                  checkOut.deductions[1].value
                    ? Number(checkOut.deductions[1].value).toLocaleString()
                    : "-"
                }}
              </td>
            </tr>
            <tr>
              <th class="px-2 py-1">مانده وام ضروری بلند مدت</th>
              <td class="px-2 py-1">
                {{
                  checkOut.deductions[2].value
                    ? Number(checkOut.deductions[2].value).toLocaleString()
                    : "-"
                }}
              </td>
              <th class="px-2 py-1">مانده وام ضروری کوتاه مدت</th>
              <td class="px-2 py-1">
                {{
                  checkOut.deductions[3].value
                    ? Number(checkOut.deductions[3].value).toLocaleString()
                    : "-"
                }}
              </td>
            </tr>
            <tr>
              <th class="px-2 py-1">مانده وام - جاری کارکنان</th>
              <td class="px-2 py-1">
                {{
                  checkOut.deductions[4].value
                    ? Number(checkOut.deductions[4].value).toLocaleString()
                    : "-"
                }}
              </td>
              <th class="px-2 py-1">مانده مساعده و علی الحسابها</th>
              <td class="px-2 py-1">
                {{
                  checkOut.deductions[5].value
                    ? Number(checkOut.deductions[5].value).toLocaleString()
                    : "-"
                }}
              </td>
            </tr>
            <tr>
              <th class="px-2 py-1">جمع کسورات</th>
              <td class="px-2 py-1" colspan="1">
                {{
                  checkOut.deductionsTotal
                    ? Number(checkOut.deductionsTotal).toLocaleString()
                    : "-"
                }}
                ریال
              </td>
            </tr>
            <tr>
              <th class="px-2 py-2 h5 font-weight-bold">
                خالص مبلغ دریافت شده
              </th>

              <td class="px-2 py-2 h5" colspan="1">
                {{
                  checkOut.checkOutAmount
                    ? Number(checkOut.checkOutAmount).toLocaleString()
                    : "-"
                }}
                ریال
              </td>
            </tr>
          </body>
        </table>
      </v-row>
      <hr />
      <v-row class="px-2 mb-2">
        <table width="100%" border="1px solid">
          <body>
            <tr>
              <th class="px-2 py-1">تنظیم کننده</th>
              <td class="px-2 py-1">
                {{ checkOut.regulator ? checkOut.regulator : "-" }}
              </td>
              <th class="px-2 py-1">تایید کننده</th>
              <td class="px-2 py-1">
                {{ checkOut.confirmer ? checkOut.confirmer : "-" }}
              </td>
              <th class="px-2 py-1">تصویب کننده</th>
              <td class="px-2 py-1">
                {{ checkOut.approver ? checkOut.approver : "-" }}
              </td>
            </tr>
          </body>
        </table>
      </v-row>
      <hr />
      <v-row>
        <v-col>
          <p style="line-height: 38px" class="h5 text-justify">
            اینجانب
            <span style="display: inline-block; width: 150px"></span> بدینوسیله
            تایید می نمایم که در تاریخ
            <span style="display: inline-block; width: 150px"></span> از شرکت
            سلامت پارسیان البرز کلیه حقوق و مزایای متعلقه خود را دریافت نموده و
            با امضای این برگه تا تاریخ
            <span style="display: inline-block; width: 150px"></span> تسویه حساب
            نموده و هیچگونه ادعای دیگری نسبت به این شرکت نخواهم داشت.
          </p>
          <v-row class="mt-5">
            <v-col></v-col>
            <v-col> <div class="h4 text-center">امضاء</div> </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!--<v-row class="my-4">
        <v-col cols="12" sm="12" md="6" lg="4" xl="4">
          <div class="disabled-fields grey lighten-3">
            <span class="h3 primary--text font-weight-bold">
              مبلغ کل تسویه حساب:
            </span>
            <span class="h3">
              {{
                checkOut.checkOutAmount
                  ? Number(checkOut.checkOutAmount).toLocaleString() + 'ریال'
                  : "-"
              }}</span
            >
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="4" xl="4">
          <div class="disabled-fields grey lighten-3">
            <span class="h3 primary--text font-weight-bold">
              مبلغ پرداخت:
            </span>
            <span class="h3">
              {{
                checkOut.paymentAmount
                  ? Number(checkOut.paymentAmount).toLocaleString() + 'ریال'
                  : "-"
              }}</span
            >
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="4" xl="4">
          <div class="disabled-fields grey lighten-3">
            <span class="h3 primary--text font-weight-bold">
              مبلغ باقی‌مانده:
            </span>
            <span class="h3">
              {{
                checkOut.checkOutRemainingAmount
                  ? Number(checkOut.checkOutRemainingAmount).toLocaleString() + 'ریال'
                  : "-"
              }}</span
            >
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="disabled-fields grey lighten-3">
            <div class="mb-3">
              <span class="h3 primary--text font-weight-bold">
                لیست پرداخت‌ها:
              </span>
            </div>
            <div
              class="px-3 py-1"
              v-for="(payment, index) in checkOut.checkOutFinancials"
              :key="index"
            >
              <span class="h3 primary--text">
                پرداخت شماره <strong> {{ index + 1 }} </strong></span
              >
              <hr class="w-100" />
              <v-row class="mt-1">
                <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                  ><span class="h3 mt-2 px-2">
                    نحوه پرداخت: <span>{{ payment.transferType }}</span>
                  </span></v-col
                >

                <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                  ><span class="h3 mt-2 px-2">
                    مبلغ پرداخت :
                    <span>{{
                      payment.amount
                        ? Number(payment.amount).toLocaleString()
                        : "-"
                    }}</span>
                    ریال
                  </span></v-col
                >
              </v-row>
              <div class="mt-1" v-if="payment.transferType == 'کارت'">
                <v-row>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                    ><span class="h3 mt-2 px-2">
                      شماره کارت فرستنده:
                      <span dir="rtl">{{
                        cardNumberSpacer(payment.cardNumberSender)
                      }}</span>
                    </span></v-col
                  >

                  <v-col cols="12" sm="12" md="6" lg="6" xl="6"
                    ><span class="h3 mt-2 px-2">
                      شماره کارت گیرنده:
                      <span dir="rtl">{{
                        cardNumberSpacer(payment.cardNumberReciever)
                      }}</span>
                    </span></v-col
                  >
                </v-row>
              </div>

              <div class="mt-2" v-if="payment.transferType == 'چک'">
                <v-row>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <span class="h3 mt-2 px-2">
                      شماره چک:
                      <span>{{ payment.chequeNumber }}</span>
                    </span>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <span class="h3 mt-2 px-2">
                      مقدار چک:
                      <span
                        >{{
                          Number(payment.chequeAmount).toLocaleString()
                        }}
                        ریال</span
                      >
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <span class="h3 mt-2 px-2">
                      نام بانک:
                      <span>{{ payment.chequeBankName }}</span>
                    </span>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <span class="h3 mt-2 px-2">
                      تاریخ چک:
                      <span>{{ payment.chequeDueDate }}</span>
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <span class="h3 mt-2 px-2">
                      شماره صیاد :
                      <span>{{ payment.chequeSayyad }}</span>
                    </span>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <span class="h3 mt-2 px-2">
                      وضعیت چک:
                      <span>{{ payment.chequeStatus }}</span>
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <span class="h3 mt-2 px-2">
                      توضیحات چک :
                      <div
                        class="px-3"
                        style="line-height: 28px; text-align: justify"
                      >
                        {{ payment.chequeDescription }}
                      </div>
                    </span>
                  </v-col>
                </v-row>
              </div>
              <v-row
                v-if="
                  payment.transferType == 'کارت' || payment.transferType == 'چک'
                "
              >
                <v-col>
                  <span class="h3 mt-2 px-2">
                    کارمزد انتقال وجه :<span>{{
                      Number(payment.transactionFee).toLocaleString() + "ریال"
                    }}</span>
                  </span>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <span class="h3 mx-2 mt-2"> فایل‌های مربوط به پرداخت : </span>
                  <span v-if="payment.PaymentFiles.length" class="h3"
                    >{{ payment.PaymentFiles.length }}
                    فایل ضمیمه دارد.
                  </span>
                  <span v-else class="h3">فایل ضمیمه ندارد.</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span class="h3 px-2">
                    توضیحات پرداخت :
                    <div
                      class="px-3"
                      style="line-height: 28px; text-align: justify"
                    >
                      {{ payment.description ? payment.description : "-" }}
                    </div>
                  </span></v-col
                >
              </v-row>
            </div>
          </div>
        </v-col>
      </v-row>
      <hr class="my-3" />
      <v-row class="my-4">
        <v-col>
          <div class="grey lighten-3">
            <div>
              <span class="h3 primary--text font-weight-bold">
                فایل تعهد‌نامه دریافت کلیه حق و حقوق:
              </span>
              <span v-if="checkOut.checkOutFileId" class="h3"
                >فایل ضمیمه دارد.</span
              >
              <span v-else class="h3">فایل ضمیمه ندارد.</span>
            </div>

            <div class="mt-4 grey lighten-3">
              <span
                class="h3 primary--text font-weight-bold"
                v-if="checkOut.checkOutFileDescription"
              >
                توضیحات فایل تعهد‌نامه:
              </span>
              <p class="h3 description-class mt-2">
                {{ checkOut.checkOutFileDescription }}
              </p>
            </div>
          </div>
        </v-col>
      </v-row>-->
    </div>
    <!--<v-dialog v-model="addPaymentItemDialog" width="600">
      <v-card>
        <v-card-title class="mb-2">
          <h4>ثبت پرداخت جدید</h4>
          <v-spacer></v-spacer>
          <v-icon
            color="red"
            class="mb-2 close-icon"
            @click="addPaymentItemDialog = false"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text> </v-card-text>
        <v-card-actions class="pt-0 mt-0 me-2">
          <v-spacer></v-spacer>
          <v-btn
            class="primary-btn"
            outlined
            @click="submitPayment()"
            :disabled="computedAddBtn"
          >
            ثبت
          </v-btn>
          <v-btn class="red-btn" outlined @click="addPaymentItemDialog = false">
            لغو
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>-->
    <v-dialog v-model="paymentItemDialog" width="600">
      <v-card class="modal-card pa-3" v-if="!editedPayment">
        <v-card-title class="mb-2">
          <h4>مشاهده جزییات پرداخت</h4>
          <v-spacer></v-spacer>
          <v-icon
            color="red"
            class="mb-2 ms-2 close-icon"
            @click="
              paymentItemDialog = false;
              editedPayment = false;
            "
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text>
          <v-row class="mt-3">
            <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="text-right">
              <span class="h2 primary--text">تاریخ :</span>
              <span class="h2">
                {{ selected.date ? selected.date : "-" }}
              </span>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="text-right">
              <span class="h2 primary--text">ساعت :</span>
              <span class="h2">
                {{ selected.time ? selected.time : "-" }}
              </span>
            </v-col>
          </v-row>
          <v-row class="mt-3">
            <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="text-right">
              <span class="h2 primary--text">مبلغ پرداخت :</span>
              <span class="h2">
                {{
                  selected.amount == "-" || selected.amount == ""
                    ? selected.amount
                    : Number(selected.amount).toLocaleString() + "ریال"
                }}
              </span>
            </v-col>
            <v-col class="text-right" cols="12" sm="12" md="6" lg="6" xl="6">
              <span class="h2 primary--text">نحوه پرداخت :</span>
              <span class="h2"> {{ selected.transferType }}</span>
            </v-col>
          </v-row>
          <v-row
            v-if="
              selected.transferType == 'کارت' || selected.transferType == 'چک'
            "
          >
            <v-col>
              <div
                v-if="selected.transferType == 'کارت'"
                class="cardInfo-box ps-2 py-2 d-flex flex-column"
              >
                <div>
                  <span class="h3 primary--text"> شماره کارت فرستنده : </span>
                  <span class="h3 float-end" dir="ltr">
                    {{
                      selected.cardNumberSender
                        ? cardNumberSpacer(selected.cardNumberSender)
                        : ""
                    }}</span
                  >
                </div>
                <div class="mt-5">
                  <span class="h3 primary--text">شماره کارت گیرنده : </span>
                  <span class="h3 float-end" dir="ltr">
                    {{
                      selected.cardNumberReciever
                        ? cardNumberSpacer(selected.cardNumberReciever)
                        : ""
                    }}</span
                  >
                </div>
              </div>
              <div
                v-if="selected.transferType == 'چک'"
                class="cardInfo-box ps-2 py-2 d-flex flex-column"
              >
                <div class="mt-5">
                  <span class="h3 primary--text">نام بانک :</span>
                  <span class="h3"> {{ selected.chequeBankName }}</span>
                </div>
                <div class="mt-5">
                  <span class="h3 primary--text">شماره چک :</span>
                  <span class="h3"> {{ selected.chequeNumber }}</span>
                </div>
                <div class="mt-5">
                  <span class="h3 primary--text">شناسه صیاد :</span>
                  <span class="h3"> {{ selected.chequeSayyad }}</span>
                </div>
                <div class="mt-5">
                  <span class="h3 primary--text">تاریخ چک :</span>
                  <span class="h3"> {{ selected.chequeDueDate }}</span>
                </div>
                <div class="mt-5">
                  <span class="h3 primary--text">وضعیت چک :</span>
                  <span class="h3"> {{ selected.chequeStatus }}</span>
                </div>
                <div class="mt-5">
                  <span class="h3 primary--text">توضیحات چک :</span>

                  <div
                    class="h3"
                    style="line-height: 22px; text-align: justify"
                  >
                    {{
                      selected.chequeDescription
                        ? selected.chequeDescription
                        : "توضیحی ثبت نشده است."
                    }}
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row
            v-if="
              selected.transferType == 'کارت' || selected.transferType == 'چک'
            "
          >
            <v-col>
              <span class="h2 primary--text">کارمزد انتقال وجه:</span>
              <span class="h2">
                {{
                  selected.transactionFee == "-" ||
                  selected.transactionFee == ""
                    ? selected.transactionFee
                    : Number(selected.transactionFee).toLocaleString() + "ریال"
                }}
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <div v-if="selected.PaymentFiles">
                <span class="h2 primary--text">
                  فایل‌ها ({{ selected.PaymentFiles.length }}):</span
                >
                <div
                  class="d-flex flex-column mt-4 px-3"
                  v-if="selected.PaymentFiles.length"
                >
                  <li
                    v-for="(item, index) in selected.PaymentFiles"
                    :key="index"
                    class="pb-4"
                    style="font-size: 14px"
                  >
                    <a :href="item.url" target="_blank">{{ item.name }}</a>
                  </li>
                </div>
                <span v-else class="h3 px-2">فایلی آپلود نشده است.</span>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span class="h2 primary--text">توضیحات :</span>
              <div
                class="h3 mt-1"
                style="line-height: 22px; text-align: justify"
              >
                {{ selected.description ? selected.description : "-" }}
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-btn
          @click="
            editedPayment = true;
            uploadedFiles = [];

            myFile.showProgress = false;
            myFile.isUploaded = false;
          "
          class="secondary-btn mb-4 me-5 pa-5"
        >
          <v-icon left>edit</v-icon>
          ویرایش</v-btn
        >
      </v-card>
      <v-card v-else class="modal-card">
        <v-card-title class="mb-2">
          <h4>ویرایش پرداخت</h4>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-3 time-row">
            <v-col>
              <vuetify-money
                v-model="selectedEdit.amount"
                label="مبلغ پرداخت(ریال)"
                :valueWhenIsEmpty="whenIsEmpty"
                :options="vMoneyOptions"
                type="number"
                outlined
                dense
              ></vuetify-money>
            </v-col>
          </v-row>
          <v-row class="time-row">
            <v-col>
              <span id="newDate5">
                <v-text-field
                  outlined
                  dense
                  type="text"
                  append-icon="calendar_today"
                  v-model="selectedEdit.date"
                  label=" تاریخ "
                  :editable="true"
                  class="date-input"
                >
                </v-text-field>
              </span>

              <date-picker
                v-model="selectedEdit.date"
                element="newDate5"
                color="#00a7b7"
              /> </v-col
            ><v-col>
              <span id="newTime5">
                <v-text-field
                  outlined
                  dense
                  type="text"
                  append-icon="mdi-clock-outline"
                  v-model="selectedEdit.time"
                  label=" ساعت "
                  :editable="true"
                  class="date-input"
                >
                </v-text-field>
              </span>

              <date-picker
                v-model="selectedEdit.time"
                element="newTime5"
                type="time"
                color="#00a7b7"
              />
            </v-col>
          </v-row>
          <v-row class="time-row">
            <v-col
              ><v-select
                v-model="selectedEdit.transferType"
                :items="transferTypes"
                item-text="text"
                item-value="value"
                label="نحوه پرداخت"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row
            class="time-row"
            v-if="
              selectedEdit.transferType == 'card' ||
              selectedEdit.transferType == 'cheque'
            "
          >
            <v-col>
              <div v-if="selectedEdit.transferType == 'card'">
                <v-text-field
                  outlined
                  dense
                  type="number"
                  class="mb-4"
                  v-model="selectedEdit.cardNumberSender"
                  label="شماره کارت فرستنده "
                  @wheel="$event.target.blur()"
                >
                </v-text-field>
                <v-text-field
                  outlined
                  dense
                  type="number"
                  v-model="selectedEdit.cardNumberReciever"
                  label="شماره کارت گیرنده "
                  @wheel="$event.target.blur()"
                >
                </v-text-field>
              </div>

              <div v-if="selectedEdit.transferType == 'cheque'">
                <v-text-field
                  outlined
                  dense
                  v-model="selectedEdit.chequeBankName"
                  label="نام بانک "
                  class="mb-4"
                >
                </v-text-field>
                <v-text-field
                  outlined
                  dense
                  v-model="selectedEdit.chequeNumber"
                  label="شماره چک"
                  class="mb-4"
                >
                </v-text-field>
                <v-text-field
                  outlined
                  dense
                  v-model="selectedEdit.chequeSayyad"
                  label="شناسه صیاد"
                  class="mb-4"
                >
                </v-text-field>
                <span id="newChequeDate">
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    append-icon="calendar_today"
                    v-model="selectedEdit.chequeDueDate"
                    label=" تاریخ چک"
                    :editable="true"
                    class="date-input mb-4"
                  >
                  </v-text-field>
                </span>

                <date-picker
                  v-model="selectedEdit.chequeDueDate"
                  element="newChequeDate"
                  color="#00a7b7"
                />

                <v-select
                  v-model="selectedEdit.chequeStatus"
                  :items="chequeStatuses"
                  item-text="text"
                  item-value="value"
                  label="وضعیت چک"
                  outlined
                  dense
                ></v-select>

                <v-textarea
                  label="توضیحات چک"
                  outlined
                  dense
                  class="mt-5"
                  v-model="selectedEdit.chequeDescription"
                >
                </v-textarea>
              </div>
            </v-col>
          </v-row>
          <v-row
            class="time-row"
            v-if="
              selectedEdit.transferType == 'card' ||
              selectedEdit.transferType == 'cheque'
            "
          >
            <v-col>
              <vuetify-money
                v-model="selectedEdit.transactionFee"
                label="کارمزد انتقال وجه (ریال)"
                :valueWhenIsEmpty="whenIsEmpty"
                :options="vMoneyOptions"
                type="number"
                outlined
                dense
              ></vuetify-money>
            </v-col>
          </v-row>
          <v-row class="time-row">
            <v-col>
              <p class="text-right mb-2">
                * شما میتوانید بیش از یک فایل انتخاب کنید.
              </p>
              <v-file-input
                @change="editedUpload(fileEdit)"
                v-model="fileEdit"
                chips
                outlined
                dense
                label=" بارگذاری فایل"
                prepend-inner-icon="upload_file"
                prepend-icon=""
                class="file-input mb-6"
              ></v-file-input>
              <v-progress-linear
                rounded
                v-if="editMyFile.showProgress"
                height="10"
                :value="editMyFile.uploadPercentage"
                class="mb-4 mt-0"
              >
              </v-progress-linear>
              <v-row>
                <div
                  v-if="editMyFile.isUploaded"
                  color="green"
                  class="mt-3 mb-1 pa-3"
                  style="font-size: 16px"
                >
                  فایل با موفقیت بارگذاری شد.
                </div>
              </v-row>
            </v-col>
          </v-row>
          <div
            v-if="selectedEdit.PaymentFiles.length"
            class="files-box d-flex flex-column mb-6"
          >
            <div class="files-title">
              فایل‌های آپلود شده ({{ selectedEdit.PaymentFiles.length }})
            </div>
            <span
              v-for="(item, index) in selectedEdit.PaymentFiles"
              :key="index"
              class="mb-2 pt-2"
            >
              <span @click="deleteFile3(index)" class="pointer">
                <v-icon class="red--text text--lighten-1 pe-2"
                  >mdi-close-circle-outline</v-icon
                >
              </span>
              <a :href="item.url" target="blank">{{ item.name }}</a>
            </span>
          </div>
          <v-row class="time-row">
            <v-col>
              <v-textarea
                v-model="selectedEdit.description"
                label="توضیحات"
                outlined
                dense
              ></v-textarea>
            </v-col>
          </v-row>

          <v-btn
            @click="
              editPaymentItem();

              fileEdit = [];
              editMyFile.isUploaded = false;
              editMyFile.showProgress = false;
            "
            :disabled="editDialogDisabled"
            class="primary-btn mt-4 mx-2"
            >ثبت</v-btn
          >
          <v-btn
            @click="
              editedPayment = false;
              fileEdit = [];
              editMyFile.isUploaded = false;
              editMyFile.showProgress = false;
            "
            class="red-btn mt-4"
            >لغو</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment-jalaali";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
export default {
  components: {
    datePicker: VuePersianDatetimePicker,
  },
  props: ["userData", "job", "checkOut"],
  data() {
    return {
      reactivity: false,
      deviceType: "",
      newPaymentCheckBox: false,
      newPayment: {
        transactionFee: "",
        description: "",
        transferType: "",
        cardNumberSender: "",
        cardNumberReciever: "",
        chequeBankName: "",
        chequeNumber: "",
        chequeDueDate: "",
        chequeSayyad: "",
        chequeStatus: "",
        chequeDescription: "",
        paymentFileIds: [],
        paymentFileUrls: [],
        paymentAmount: 0,
      },
      paymentItemDialog: false,
      selected: {},
      selectedEdit: {
        cardNumberSender: "",
        cardNumberReciever: "",
        chequeBankName: "",
        chequeNumber: "",
        chequeDueDate: "",
        chequeDescription: "",
        chequeSayyad: "",
        chequeStatus: "",
        amount: "",
        description: "",
        time: "",
        transactionFee: "",
        transferType: "",
        PaymentFiles: [],
      },
      editedPayment: false,
      CurrentPage: 1,
      PerPage: 10,
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      paymentsFields: [
        { key: "index", label: "#" },
        { key: "date", label: "تاریخ" },
        { key: "time", label: "ساعت" },
        { key: "transferType", label: "نحوه پرداخت" },
        { key: "amount", label: "مبلغ (ریال)" },
        { key: "description", label: "توضیحات" },
        { key: "operation", label: "عملیات" },
      ],
      addPaymentItemDialog: false,
      TotalRows: "",
      drName: "",
      nurseName: "",
      Filter: "",
      Busy: true,
      Fields: [
        { key: "index", label: "#" },
        { key: "type", label: "نوع مرخصی" },
        { key: "userName", label: "نام کارمند" },
        { key: "userRole", label: "سمت" },
        { key: "startDate", label: "زمان شروع" },
        { key: "endDate", label: "زمان پایان" },
        { key: "state", label: "حالت" },
        { key: "status", label: "وضعیت" },
        { key: "description", label: "توضیحات" },
      ],
      Items: [],
      dateFrom: moment(new Date()).format("jYYYY/jMM/jDD"),
      dateTo: moment(new Date()).format("jYYYY/jMM/jDD"),
      timeFrom: "00:00",
      timeTo: "23:59",

      currentDate: moment(new Date()).format("jYYYY-jMM-jDD"),
      role: "",
      showProgress: false,
      uploadButDisable: false,
      paymentUploadButDisable: false,
      variant: "info",
      striped: true,
      max: 100,
      uploadPercentage: 0,
      isUploaded: false,
      file: [],
      fileEdit: [],
      paymentFile: [],
      paymentIsUploaded: false,
      paymentUploadPercentage: 0,
      paymentShowProgress: false,
      reportFiles: [],
      request: {},
      myFile: new Object({
        uploadPercentage: 0,
      }),
      editMyFile: new Object({
        uploadPercentage: 0,
      }),
      paymentMyFile: new Object({
        paymentUploadPercentage: 0,
      }),
      fileBusy: false,
      fileFilter: "",
      edited: false,
      checkOutData: {
        checkOutDate: "",
        startWorkingDate: "",
        checkOutAmount: "",
        checkOutRemainingAmount: "",
        fileId: "",
        checkOutFileDescription: "",
        paymentDescription: "",
        transferType: "",
        cardNumberSender: "",
        cardNumberReciever: "",
        chequeBankName: "",
        chequeNumber: "",
        chequeDueDate: "",
        chequeSayyad: "",
        chequeStatus: "",
        chequeDescription: "",
        paymentFileIds: [],
        paymentFileUrls: [],
        paymentAmount: "",
        salaryAndBenefits: [
          {
            title: "fixedSalary",
            value: "",
          },
          {
            title: "foodRights",
            value: "",
          },
          {
            title: "housingRights",
            value: "",
          },
          {
            title: "childrenRights",
            value: "",
          },
        ],
        demands: [
          {
            title: "overtime",
            value: "",
            duration: "",
            description: "",
          },
          {
            title: "bonus",
            value: "",
            duration: "",
            description: "",
          },
          {
            title: "rewards",
            value: "",
            duration: "",
            description: "",
          },
          {
            title: "childrenRights",
            value: "",
            duration: "",
            description: "",
          },
          {
            title: "minBonus",
            value: "",
            duration: "",
            description: "",
          },
          {
            title: "shiftRights",
            value: "",
            duration: "",
            description: "",
          },
          {
            title: "workingFriday",
            value: "",
            duration: "",
            description: "",
          },
          {
            title: "compensatedAbsences",
            value: "",
            duration: "",
            description: "",
          },
        ],
        demandsTotal: "",
        deductions: [
          {
            title: "tax",
            value: "",
          },
          {
            title: "employeeInsurance",
            value: "",
          },
          {
            title: "longTermLoan",
            value: "",
          },
          {
            title: "shortTermLoan",
            value: "",
          },
          {
            title: "staffLoan",
            value: "",
          },
          {
            title: "onAccount",
            value: "",
          },
        ],
        deductionsTotal: "",
      },
      transferTypes: [
        { value: "card", text: "کارت" },
        { value: "cheque", text: "چک" },
        { value: "cash", text: "نقدی" },
      ],
      makeCheckOut: false,
      editedFileDescription: "",
      addFileDescription: "",
      chequeStatuses: [
        { text: "پاس شده", value: "passed" },
        { text: "برگشت خورده", value: "returned" },
        { text: "ثبت شده", value: "submitted" },
      ],
      newFileUrl: "",
      newPaymentFileUrl: "",
      paymentUploadedFiles: [],
      newPaymentUploadedFiles: [],
      showName: [],
      showName2: [],
      showName3: [],
      checkoutFileName: "",
      editChekoutFileName: "",
      paymentFileIds: [],
      paymentFileUrls: [],
      isFileUploaded: false,
      changedFile: false,
      hasDescription: "withoutDescription",
      paymentsList: [],
      newFilesList: [],
      financialId: "",
      editFileIds: [],
      salaryAndBenefitsFields: [
        { key: "title", label: "عنوان" },
        { key: "value", label: "مقدار" },
      ],
      faSalaryAndBenefits: [
        {
          text: "حقوق ثابت",
          value: "fixedSalary",
        },
        {
          text: "حق خواروبار",
          value: "foodRights",
        },
        {
          text: "حق مسکن",
          value: "housingRights",
        },
        {
          text: "حق اولاد",
          value: "childrenRights",
        },
      ],
      faDemands: [
        {
          text: "اضافه کاری",
          value: "overtime",
        },
        {
          text: "سنوات",
          value: "bonus",
        },
        {
          text: "عیدی وپاداش سالیانه",
          value: "rewards",
        },
        {
          text: "حق اولاد",
          value: "childrenRights",
        },
        {
          text: "پایه سنوات",
          value: "minBonus",
        },
        {
          text: "حق شیفت",
          value: "shiftRights",
        },
        {
          text: "جمعه کاری",
          value: "workingFriday",
        },
        {
          text: "بازخرید مرخصی",
          value: "compensatedAbsences",
        },
      ],
      demandsFields: [
        { key: "title", label: "عنوان" },
        { key: "duration", label: "مدت" },
        { key: "value", label: "مقدار" },
        { key: "description", label: "شرح محاسبات" },
      ],
      faDeductions: [
        {
          text: "مالیات حقوق و عیدی ( مالیات مرخصی )",
          value: "tax",
        },
        {
          text: "بیمه سهم کارمند",
          value: "employeeInsurance",
        },
        {
          text: "مانده وام ضروری بلند مدت",
          value: "longTermLoan",
        },
        {
          text: "مانده وام ضروری کوتاه مدت",
          value: "shortTermLoan",
        },
        {
          text: "مانده وام - جاری کارکنان",
          value: "staffLoan",
        },
        {
          text: "مانده مساعده و علی الحسابها",
          value: "onAccount",
        },
      ],
      deductionsFields: [
        { key: "title", label: "عنوان" },
        { key: "value", label: "مقدار" },
      ],
    };
  },
  computed: {
    computedBenefitsTotal() {
      let total = 0;
      if (this.checkOutData.salaryAndBenefits.length) {
        this.checkOutData.salaryAndBenefits.forEach((item) => {
          total = Number(item.value) + total;
        });
      }
      return total;
    },
    computedDemandsTotal() {
      let total = 0;
      if (this.checkOutData.demands.length) {
        this.checkOutData.demands.forEach((item) => {
          total = Number(item.value) + total;
        });
        this.checkOutData.demandsTotal = total;
      }
      return total;
    },
    computedDeductionsTotal() {
      let total = 0;
      if (this.checkOutData.deductions.length) {
        this.checkOutData.deductions.forEach((item) => {
          total = Number(item.value) + total;
        });
        this.checkOutData.deductionsTotal = total;
      }
      return total;
    },
    //computedRemainingAmount() {
    //  //if (!this.newPayment.paymentAmount) {
    //  //  return (
    //  //    Number(this.checkOutData.checkOutRemainingAmount).toLocaleString() +
    //  //    " ریال "
    //  //  );

    //  //} else if (this.newPayment.paymentAmount) {
    //  //  if (
    //  //    Number(
    //  //      this.checkOutData.checkOutRemainingAmount -
    //  //        this.newPayment.paymentAmount
    //  //    ) > 0
    //  //  ) {
    //  //    return (
    //  //      Number(
    //  //         (Number(this.computedDemandsTotal) - Number(this.computedDeductionsTotal)) -
    //  //          this.newPayment.paymentAmount
    //  //      ).toLocaleString() + " ریال"
    //  //    );
    //  //  } else {
    //  //    return "مقدار محاسبه شده منفی است";
    //  //  }
    //  //}

    //  let paymentTotal = 0;
    //  this.checkOutData.checkOutFinancials.forEach((item) => {
    //    paymentTotal = Number(item.amount) + paymentTotal;
    //  });
    //  paymentTotal =
    //    Number(paymentTotal) + Number(this.newPayment.paymentAmount);
    //  return (
    //    Number(this.computedDemandsTotal) -
    //    Number(this.computedDeductionsTotal) -
    //    Number(paymentTotal) +
    //    Number(this.newPayment.paymentAmount)
    //  );
    //},
    //computedPaymentAmount() {
    //  if (
    //    this.computedDemandsTotal -
    //      this.computedDeductionsTotal -
    //      this.computedRemainingAmount >
    //    0
    //  ) {
    //    return Number(
    //      this.computedDemandsTotal -
    //        this.computedDeductionsTotal -
    //        this.computedRemainingAmount
    //    );
    //  } else {
    //    return Number(
    //      this.computedDemandsTotal -
    //        this.computedDeductionsTotal -
    //        Number(
    //          this.checkOutData.checkOutRemainingAmount -
    //            this.newPayment.paymentAmount
    //        )
    //    );
    //  }
    //},
    editDialogDisabled() {
      if (
        this.selectedEdit.amount &&
        this.selectedEdit.date &&
        this.selectedEdit.time &&
        this.selectedEdit.description &&
        this.selectedEdit.transferType
      ) {
        if (this.selectedEdit.transferType == "card") {
          if (
            this.selectedEdit.cardNumberSender &&
            this.selectedEdit.cardNumberReciever &&
            this.selectedEdit.transactionFee
          ) {
            return false;
          } else {
            return true;
          }
        } else if (this.selectedEdit.transferType == "cheque") {
          if (
            this.selectedEdit.chequeBankName &&
            this.selectedEdit.chequeNumber &&
            this.selectedEdit.chequeDueDate &&
            this.selectedEdit.chequeSayyad &&
            this.selectedEdit.chequeStatus &&
            this.selectedEdit.transactionFee
          ) {
            return false;
          } else {
            return true;
          }
        }
      } else {
        return true;
      }
    },
    //computedAddBtn() {
    //  if (
    //    this.checkOutData.paymentDescription &&
    //    this.checkOutData.transferType
    //  ) {
    //    if (this.checkOutData.transferType == "card") {
    //      if (
    //        this.checkOutData.cardNumberSender &&
    //        this.checkOutData.cardNumberReciever
    //      ) {
    //        return false;
    //      } else return true;
    //    } else if (this.checkOutData.transferType == "cheque") {
    //      if (
    //        this.checkOutData.chequeBankName &&
    //        this.checkOutData.chequeAmount &&
    //        this.checkOutData.chequeNumber &&
    //        this.checkOutData.chequeDueDate &&
    //        this.checkOutData.chequeSayyad &&
    //        this.checkOutData.chequeDescription &&
    //        this.checkOutData.chequeStatus
    //      ) {
    //        return false;
    //      } else return true;
    //    } else return false;
    //  } else return true;
    //},
    computedDisabled() {
      if (
        this.computedDemandsTotal > 0 &&
        this.computedDeductionsTotal > 0 &&
        this.computedBenefitsTotal > 0 &&
        this.computedDemandsTotal - this.computedDeductionsTotal > 0 &&
        this.checkOutData.fileId &&
        this.checkOutData.paymentDescription &&
        this.checkOutData.transferType &&
        this.checkOutData.paymentAmount &&
        this.checkOutData.checkOutDate &&
        this.checkOutData.leaveDemandsNum &&
        this.checkOutData.serviceRecordsTotal &&
        this.checkOutData.approver &&
        this.checkOutData.confirmer &&
        this.checkOutData.regulator
      ) {
        if (this.checkOutData.transferType == "card") {
          if (
            this.checkOutData.cardNumberSender &&
            this.checkOutData.cardNumberReciever &&
            this.checkOutData.transactionFee
          ) {
            return false;
          } else return true;
        } else if (this.checkOutData.transferType == "cheque") {
          if (
            this.checkOutData.chequeBankName &&
            this.checkOutData.chequeNumber &&
            this.checkOutData.chequeDueDate &&
            this.checkOutData.chequeSayyad &&
            this.checkOutData.chequeDescription &&
            this.checkOutData.chequeStatus &&
            this.checkOutData.transactionFee
          ) {
            return false;
          } else return true;
        } else return false;
      } else return true;
    },
    computedDisabledForEdit() {
      if (
        this.checkOutData.checkOutFileId &&
        this.computedDemandsTotal > 0 &&
        this.computedDeductionsTotal > 0 &&
        this.computedBenefitsTotal > 0 &&
        this.computedDemandsTotal - this.computedDeductionsTotal > 0 &&
        this.checkOutData.checkOutDate &&
        this.checkOutData.leaveDemandsNum &&
        this.checkOutData.serviceRecordsTotal &&
        this.checkOutData.approver &&
        this.checkOutData.confirmer &&
        this.checkOutData.regulator
      ) {
        if (this.newPaymentCheckBox) {
          if (
            this.newPayment.transferType &&
            this.newPayment.paymentAmount &&
            this.newPayment.description
          ) {
            if (this.newPayment.transferType == "card") {
              if (
                this.newPayment.cardNumberSender &&
                this.newPayment.cardNumberReciever &&
                this.newPayment.transactionFee
              ) {
                return false;
              } else return true;
            } else if (this.newPayment.transferType == "cheque") {
              if (
                this.newPayment.chequeBankName &&
                this.newPayment.chequeNumber &&
                this.newPayment.chequeDueDate &&
                this.newPayment.chequeSayyad &&
                this.newPayment.chequeDescription &&
                this.newPayment.chequeStatus &&
                this.newPayment.transactionFee
              ) {
                return false;
              } else return true;
            }
          } else {
            return true;
          }
        }
      } else return true;
    },
  },

  methods: {
    cardNumberSpacer(number) {
      number = number.match(new RegExp(".{1,4}", "g")).join("-");
      return number;
    },
    emptyNewPayment() {
      this.newPaymentCheckBox = false;
      this.newPayment = {
        transactionFee: "",
        description: "",
        transferType: "",
        cardNumberSender: "",
        cardNumberReciever: "",
        chequeBankName: "",
        chequeNumber: "",
        chequeDueDate: "",
        chequeSayyad: "",
        chequeStatus: "",
        chequeDescription: "",
        paymentFileIds: [],
        paymentFileUrls: [],
        paymentAmount: "",
      };
    },
    openModal(item) {
      this.editMyFile = new Object({
        uploadPercentage: 0,
      });

      this.financialId = item.id;
      this.editedPayment = false;
      this.selected = JSON.parse(JSON.stringify(item));
      this.selectedEdit = JSON.parse(JSON.stringify(this.selected));

      this.editFileIds = [];

      this.selectedEdit.PaymentFiles.forEach((x) => {
        this.editFileIds.push(x.id);
      });

      this.selectedEdit.paymentFileIds = this.editFileIds;
      this.selectedEdit.transferType = this.transferTypes.filter(
        (x) => x.text == this.selected.transferType
      )[0].value;

      if (this.selectedEdit.transferType == "cheque") {
        this.selectedEdit.chequeStatus = this.chequeStatuses.filter(
          (x) => x.text == this.selectedEdit.chequeStatus
        )[0].value;
      }
      this.paymentItemDialog = true;
    },

    cardNumberSpacer(number) {
      number = number.match(new RegExp(".{1,4}", "g")).join("-");
      return number;
    },

    addCheckOut() {
      this.vLoading = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/hr/financial/newCheckOut",
          {
            userId: this.$route.params.id,
            checkOutDate: this.checkOutData.checkOutDate,
            startWorkingDate: this.checkOutData.startWorkingDate,
            serviceRecordsTotal: this.checkOutData.serviceRecordsTotal,
            leaveDemandsNum: this.checkOutData.leaveDemandsNum,
            salaryAndBenefits: this.checkOutData.salaryAndBenefits,
            demands: this.checkOutData.demands,
            deductions: this.checkOutData.deductions,
            regulator: this.checkOutData.regulator,
            confirmer: this.checkOutData.confirmer,
            approver: this.checkOutData.approver,
            //checkOutAmount: this.checkOutData.checkOutAmount,
            paymentAmount: this.checkOutData.paymentAmount,
            paymentDescription: this.checkOutData.paymentDescription,
            transferType: this.checkOutData.transferType,
            transactionFee:
              this.checkOutData.transferType == "cheque" ||
              this.checkOutData.transferType == "card"
                ? this.checkOutData.transactionFee
                : "",
            chequeAmount:
              this.checkOutData.transferType == "cheque"
                ? this.checkOutData.paymentAmount
                : "",
            chequeNumber:
              this.checkOutData.transferType == "cheque"
                ? this.checkOutData.chequeNumber
                : "",
            chequeBankName:
              this.checkOutData.transferType == "cheque"
                ? this.checkOutData.chequeBankName
                : "",
            chequeDueDate:
              this.checkOutData.transferType == "cheque"
                ? this.checkOutData.chequeDueDate
                : "",
            chequeSayyad:
              this.checkOutData.transferType == "cheque"
                ? this.checkOutData.chequeSayyad
                : "",
            chequeDescription:
              this.checkOutData.transferType == "cheque"
                ? this.checkOutData.chequeDescription
                : "",
            chequeStatus:
              this.checkOutData.transferType == "cheque"
                ? this.checkOutData.chequeStatus
                : "",
            cardNumberSender:
              this.checkOutData.transferType == "card"
                ? this.checkOutData.cardNumberSender
                : "",
            cardNumberReciever:
              this.checkOutData.transferType == "card"
                ? this.checkOutData.cardNumberReciever
                : "",
            fileId: this.checkOutData.fileId,
            fileDescription: this.checkOutData.checkOutFileDescription,
            paymentFileIds: this.checkOutData.paymentFileIds,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.vLoading = false;
            this.toast(res.data, "success");

            this.paymentUploadedFiles = [];
            this.showName = [];
            this.paymentFileIds = [];
            this.$emit("getInfo");
            this.edited = false;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.vLoading = false;
            this.edited = false;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.vLoading = false;
          this.edited = false;
        });
    },
    editCheckOut() {
      this.vLoading = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/hr/financial/editCheckout",
          {
            userId: this.$route.params.id,
            isCheckOut: this.checkOut.isCheckOut,
            checkOutDate: this.checkOutData.checkOutDate,
            startWorkingDate: this.checkOutData.startWorkingDate,
            serviceRecordsTotal: this.checkOutData.serviceRecordsTotal,
            leaveDemandsNum: this.checkOutData.leaveDemandsNum,
            salaryAndBenefits: this.checkOutData.salaryAndBenefits,
            demands: this.checkOutData.demands,
            deductions: this.checkOutData.deductions,
            regulator: this.checkOutData.regulator,
            confirmer: this.checkOutData.confirmer,
            approver: this.checkOutData.approver,
            paymentAmount: this.checkOutData.paymentAmount,
            transferType: this.newPaymentCheckBox
              ? this.newPayment.transferType
              : "",
            transactionFee:
              this.newPayment.transferType == "cheque" ||
              this.newPayment.transferType == "card"
                ? this.newPayment.transactionFee
                : "",
            chequeAmount:
              this.newPayment.transferType == "cheque"
                ? this.newPayment.paymentAmount
                : "",
            chequeNumber:
              this.newPayment.transferType == "cheque"
                ? this.newPayment.chequeNumber
                : "",
            chequeBankName:
              this.newPayment.transferType == "cheque"
                ? this.newPayment.chequeBankName
                : "",
            chequeDueDate:
              this.newPayment.transferType == "cheque"
                ? this.newPayment.chequeDueDate
                : "",
            chequeSayyad:
              this.newPayment.transferType == "cheque"
                ? this.newPayment.chequeSayyad
                : "",
            chequeDescription:
              this.newPayment.transferType == "cheque"
                ? this.newPayment.chequeDescription
                : "",
            chequeStatus:
              this.newPayment.transferType == "cheque"
                ? this.newPayment.chequeStatus
                : "",
            cardNumberSender:
              this.newPayment.transferType == "card"
                ? this.newPayment.cardNumberSender
                : "",
            cardNumberReciever:
              this.newPayment.transferType == "card"
                ? this.newPayment.cardNumberReciever
                : "",
            paymentAmount: this.newPayment.paymentAmount,
            fileId: this.checkOutData.checkOutFileId,
            fileDescription: this.checkOutData.checkOutFileDescription,
            paymentFileIds: this.newPayment.paymentFileIds,
            paymentDescription: this.newPayment.description,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.vLoading = false;
            this.toast(res.data, "success");
            this.$emit("getInfo");
            this.newPaymentUploadedFiles = [];
            this.edited = false;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.vLoading = false;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.vLoading = false;
        });
    },
    //submitPayment() {
    //  this.vLoading = true;
    //  this.$http
    //    .post(
    //      this.baseUrl + "",
    //      {
    //        userId: this.$route.params.id,
    //        payments: this.paymentsList,
    //        checkOutAmount: this.checkOutData.checkOutAmount,
    //        checkOutRemainingAmount: this.checkOutData.checkOutRemainingAmount
    //          ? this.checkOutData.checkOutRemainingAmount
    //          : "0",
    //        paymentDescription: this.checkOutData.paymentDescription,
    //        transferType: this.checkOutData.transferType,
    //        transactionFee:
    //          this.checkOutData.transferType == "cheque" ||
    //          this.checkOutData.transferType == "card"
    //            ? this.checkOutData.transactionFee
    //            : "",
    //        chequeAmount:
    //          this.checkOutData.transferType == "cheque"
    //            ? this.checkOutData.chequeAmount
    //            : "",
    //        chequeNumber:
    //          this.checkOutData.transferType == "cheque"
    //            ? this.checkOutData.chequeNumber
    //            : "",
    //        chequeBankName:
    //          this.checkOutData.transferType == "cheque"
    //            ? this.checkOutData.chequeBankName
    //            : "",
    //        chequeDueDate:
    //          this.checkOutData.transferType == "cheque"
    //            ? this.checkOutData.chequeDueDate
    //            : "",
    //        chequeSayyad:
    //          this.checkOutData.transferType == "cheque"
    //            ? this.checkOutData.chequeSayyad
    //            : "",
    //        chequeDescription:
    //          this.checkOutData.transferType == "cheque"
    //            ? this.checkOutData.chequeDescription
    //            : "",
    //        chequeStatus:
    //          this.checkOutData.transferType == "cheque"
    //            ? this.checkOutData.chequeStatus
    //            : "",
    //        cardNumberSender:
    //          this.checkOutData.transferType == "card"
    //            ? this.checkOutData.cardNumberSender
    //            : "",
    //        cardNumberReciever:
    //          this.checkOutData.transferType == "card"
    //            ? this.checkOutData.cardNumberReciever
    //            : "",
    //        paymentFileIds: this.checkOutData.paymentFileIds,
    //      },
    //      {
    //        headers: {
    //          Authorization: "Bearer: " + localStorage.getItem("token"),
    //        },
    //      }
    //    )
    //    .then((res) => {
    //      if (res.status == 200) {
    //        this.vLoading = false;
    //        this.toast(res.data, "success");
    //        this.paymentsList = res.data;
    //        this.paymentUploadedFiles = [];
    //        this.showName = [];
    //        this.paymentFileIds = [];
    //      } else {
    //        this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
    //        this.vLoading = false;
    //        this.edited = false;
    //      }
    //    })
    //    .catch((err) => {
    //      this.toast("خطا: " + err.response.data, "error");
    //      this.vLoading = false;
    //      this.edited = false;
    //    });
    //},
    newUpload(file) {
      // NOTE (m-myUpload) to upload files
      this.myFile.showProgress = true;
      this.myFile.variant = "info";
      this.myFile.uploadPercentage = 0;
      this.myFile.isUploaded = false;
      this.myFile.uploadButDisable = true;
      var formData = new FormData();
      this.checkoutFileName = file.name;
      formData.append(file.name, file);

      this.$http
        .post(
          this.baseUrl +
            "/clinic/hr/employee/checkoutUpload?userId=" +
            this.$route.params.id,
          formData,
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
            onUploadProgress: ((progressEvent) => {
              this.myFile.uploadPercentage =
                Math.round((progressEvent.loaded * 100) / progressEvent.total) -
                1;
            }).bind(this),
          }
        )
        .then((d) => {
          if (d.status == 200) {
            this.myFile.isUploaded = true;
            this.myFile.uploadPercentage = 100;
            this.myFile.striped = false;
            this.myFile.variant = "success";
            this.myFile.uploadButDisable = false;
            this.checkOutData.fileId = d.data.id;
            this.checkOutData.fileUrl = d.data.url;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    editUpload(file) {
      // NOTE (m-myUpload) to upload files
      this.myFile.showProgress = true;
      this.myFile.variant = "info";
      this.myFile.uploadPercentage = 0;
      this.myFile.isUploaded = false;
      this.myFile.uploadButDisable = true;
      var formData = new FormData();
      this.editChekoutFileName = file.name;

      formData.append(file.name, file);

      this.$http
        .post(
          this.baseUrl +
            "/clinic/hr/employee/checkoutUpload?userId=" +
            this.$route.params.id,
          formData,
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
            onUploadProgress: ((progressEvent) => {
              this.myFile.uploadPercentage =
                Math.round((progressEvent.loaded * 100) / progressEvent.total) -
                1;
            }).bind(this),
          }
        )
        .then((d) => {
          if (d.status == 200) {
            this.myFile.isUploaded = true;
            this.myFile.uploadPercentage = 100;
            this.myFile.striped = false;
            this.myFile.variant = "success";
            this.myFile.uploadButDisable = false;
            this.checkOutData.checkOutFileId = d.data.id;
            this.checkOutData.checkOutFileUrl = d.data.url;
            this.isFileUploaded = true;
            this.newFileUrl = d.data.url;
            this.changedFile = true;
            this.editedFileUrl = d.data.url;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.isFileUploaded = false;
        });
    },
    paymentUpload(file) {
      // NOTE (m-myUpload) to upload files
      this.paymentMyFile.paymentShowProgress = true;
      this.paymentMyFile.variant = "info";
      this.paymentMyFile.paymentUploadPercentage = 0;
      this.paymentMyFile.paymentIsUploaded = false;
      this.paymentMyFile.paymentUploadButDisable = true;
      var formData = new FormData();

      formData.append(file.name, file);

      this.$http
        .post(this.baseUrl + "/clinic/financial/document/upload", formData, {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
          onUploadProgress: ((progressEvent) => {
            this.paymentMyFile.paymentUploadPercentage =
              Math.round((progressEvent.loaded * 100) / progressEvent.total) -
              1;
          }).bind(this),
        })
        .then((d) => {
          if (d.status == 201) {
            this.showName.push(file.name);
            this.paymentMyFile.paymentIsUploaded = true;
            this.paymentMyFile.paymentUploadPercentage = 100;
            this.paymentMyFile.striped = false;
            this.paymentMyFile.variant = "success";
            this.paymentMyFile.paymentUploadButDisable = false;
            this.paymentUploadedFiles.push(d.data);
            this.checkOutData.paymentFileIds.push(d.data.id);
            this.checkOutData.paymentFileUrls.push(d.data.url);
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    editPaymentUpload(file) {
      // NOTE (m-myUpload) to upload files
      this.paymentMyFile.paymentShowProgress = true;
      this.paymentMyFile.variant = "info";
      this.paymentMyFile.paymentUploadPercentage = 0;
      this.paymentMyFile.paymentIsUploaded = false;
      this.paymentMyFile.paymentUploadButDisable = true;
      var formData = new FormData();

      formData.append(file.name, file);

      this.$http
        .post(this.baseUrl + "/clinic/financial/document/upload", formData, {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
          onUploadProgress: ((progressEvent) => {
            this.paymentMyFile.paymentUploadPercentage =
              Math.round((progressEvent.loaded * 100) / progressEvent.total) -
              1;
          }).bind(this),
        })
        .then((d) => {
          if (d.status == 201) {
            this.showName2.push(file.name);
            this.paymentMyFile.paymentIsUploaded = true;

            this.paymentMyFile.paymentUploadPercentage = 100;
            this.paymentMyFile.striped = false;
            this.paymentMyFile.variant = "success";
            this.paymentMyFile.paymentUploadButDisable = false;
            this.newPaymentUploadedFiles.push(d.data);
            this.newPayment.paymentFileIds.push(d.data.id);
            this.newPayment.paymentFileUrls.push(d.data.url);
          }
        })
        .catch((err) => {
          console.log(err);
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    editedUpload(file) {
      // NOTE (m-myUpload) to upload files
      this.editMyFile.showProgress = true;
      this.editMyFile.variant = "info";
      this.editMyFile.uploadPercentage = 0;
      this.editMyFile.isUploaded = false;
      this.editMyFile.uploadButDisable = true;
      var formData = new FormData();
      this.showName3.push(file.name);
      formData.append(file.name, file);

      this.$http
        .post(this.baseUrl + "/clinic/financial/document/upload", formData, {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
          onUploadProgress: ((progressEvent) => {
            this.editMyFile.uploadPercentage =
              Math.round((progressEvent.loaded * 100) / progressEvent.total) -
              1;
          }).bind(this),
        })
        .then((d) => {
          if (d.status == 201) {
            this.editMyFile.isUploaded = true;
            this.editMyFile.uploadPercentage = 100;
            this.editMyFile.striped = false;
            this.editMyFile.variant = "success";
            this.editMyFile.uploadButDisable = false;

            this.selectedEdit.PaymentFiles.push(d.data);

            let filesLength = this.selectedEdit.PaymentFiles.length;
            let namesLength = this.showName3.length;

            this.selectedEdit.PaymentFiles[filesLength - 1].name =
              this.showName3[namesLength - 1];

            this.selectedEdit.paymentFileIds.push(d.data.id);
          }
        })
        .catch((err) => {
          console.log(err);
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    editPaymentItem() {
      this.vLoading = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/financial/document/editPayment",
          {
            financialId: this.financialId,
            date: this.selectedEdit.date + this.selectedEdit.time,
            amount: this.selectedEdit.amount,
            description: this.selectedEdit.description,
            transactionFee:
              this.selectedEdit.transferType == "cheque" ||
              this.selectedEdit.transferType == "card"
                ? this.selectedEdit.transactionFee
                : "",
            transferType: this.selectedEdit.transferType,
            chequeAmount:
              this.selectedEdit.transferType == "cheque"
                ? this.selectedEdit.amount
                : "",
            chequeNumber:
              this.selectedEdit.transferType == "cheque"
                ? this.selectedEdit.chequeNumber
                : "",
            chequeBankName:
              this.selectedEdit.transferType == "cheque"
                ? this.selectedEdit.chequeBankName
                : "",
            chequeDueDate:
              this.selectedEdit.transferType == "cheque"
                ? this.selectedEdit.chequeDueDate
                : "",
            chequeSayyad:
              this.selectedEdit.transferType == "cheque"
                ? this.selectedEdit.chequeSayyad
                : "",
            chequeStatus:
              this.selectedEdit.transferType == "cheque"
                ? this.selectedEdit.chequeStatus
                : "",
            chequeDescription:
              this.selectedEdit.transferType == "cheque"
                ? this.selectedEdit.chequeDescription
                : "",
            cardNumberSender:
              this.selectedEdit.transferType == "card"
                ? this.selectedEdit.cardNumberSender
                : "",
            cardNumberReciever:
              this.selectedEdit.transferType == "card"
                ? this.selectedEdit.cardNumberReciever
                : "",
            fileIds: this.selectedEdit.paymentFileIds,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.vLoading = false;
            this.toast(res.data, "success");
            this.editedPayment = false;
            this.paymentItemDialog = false;
            //this.edited = false;
            this.$emit("getInfo");
            setTimeout(() => {
              this.checkOutData = JSON.parse(JSON.stringify(this.checkOut));
            }, 1000);

            this.editedPayment = false;

            this.editMyFile = new Object({
              uploadPercentage: 0,
            });
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.vLoading = false;
            this.editedPayment = false;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.vLoading = false;
          this.edited = false;
        });
    },
    deleteFile(index) {
      this.paymentUploadedFiles.splice(index, 1);
      this.showName.splice(index, 1);
      this.checkOutData.paymentFileIds.splice(index, 1);
    },
    deleteFile2(index) {
      this.newPaymentUploadedFiles.splice(index, 1);
      this.showName2.splice(index, 1);
      this.newPayment.paymentFileIds.splice(index, 1);
    },
    deleteFile3(index) {
      this.selectedEdit.PaymentFiles.splice(index, 1);
      this.selectedEdit.paymentFileIds.splice(index, 1);
    },
    printEmployeeCheckOut() {
      //NOTE making a Employee CheckOut pdf for printing
      this.$htmlToPaper("employeeCheckOut");
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.TotalRows = filteredItems.length;
      this.CurrentPage = 1;
    },
  },
  mounted() {
    if (window.screen.width < 950) {
      this.deviceType = "mobile";
    } else {
      if (document.body.clientWidth < 950) {
        this.deviceType = "mobile";
      } else {
        this.deviceType = "desktop";
      }
    }
    this.role = localStorage.getItem("role");
  },
};
</script>
<style lang="scss">
.modal-card {
  .cardInfo-box {
    border-right: 2px solid #ccc;
    width: fit-content !important;
  }
  .v-chip {
    display: none !important;
  }
}

.checkout-table {
  .table td {
    padding: 10px 20px 0 !important;
  }
}
</style>
